import './Toolbar.css';
import  {IconButton, TextButton} from './Button.js';

function Toolbar(props){
	
	const id = props.id;
	let buttons = props.buttons || [];
	
	return (
		<div id={props.id} className={props._className || 'Toolbar'} style={{'display': buttons.length == 0 ? 'none' : null}}>
			{buttons.map((button) => <IconButton key={button['id']} id={id + '_' + button['id']} _className={button['_className']} fn={button['fn']} label={button['label']} icon={button['icon']} disabled={button['disabled']}/>)}
		</div>
	)
	
}

export default Toolbar;