import './ViewEditSingleFormTable.css'
import { useState } from "react";
import {Form} from '../Components/Form.js';
import Table from '../Components/Table.js';
import TableForm from '../Components/TableForm.js';
import Toolbar from '../Components/Toolbar.js';

function ViewEditSingleFormTable(props){
	
	var formData = props.formData;
		
	let toolbarButtons = props.toolbarButtons;
	
	return(
		<div className = 'ViewEditSingleFormTable'>
			<Form id = 'modifyForm' 
				title={props.title} 
				sections = {formData} 
				buttons= {props.formButtons} 
				_className = 'SearchForm'/>
			{toolbarButtons!=null && <Toolbar buttons={toolbarButtons} />}
			<TableForm id='table' 
				TableData={props.TableData} 
				selectFunction={props.selectFunction}
				updater={props.updater}/>
		</div>
	)
}

export default ViewEditSingleFormTable;