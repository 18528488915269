import './VariableSetViewMany.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faPlus, faXmark, faPenToSquare, faAngleRight, faAngleLeft, faRotate} from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

function VariableSetViewMany(props){
	
	let {instructionSetId} = useParams();
	
	var defaultSearchData = {
								'name': ''
							}
	
	const [searchData, setSearchData] = useState(defaultSearchData);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [addDisabled, setAddDisabled] = useState(false);
	const [tablebackDisabled, setTablebackDisabled] = useState(true);
	const [tableforwardDisabled, setTableforwardDisabled] = useState(true);
	const [viewDisabled, setViewDisabled] = useState(true);
	const [editDisabled, setEditDisabled] = useState(true);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [validateDisabled, setValidateDisabled] = useState(true);
	const [page, setPage] = useState(0);
	
	var searchFormData = [
		{
			'metadata': {
							id: 'searchFormData',
							title: null,
							expandable: true,
							_className: 'SearchFormSection',
							fields: [
								{
									id: 'name',
									label: 'Name',
									placeholder: 'Name',
								},
								{
									id: 'description',
									label: 'Description',
									placeholder: 'Description',
								}								
							]
						},
			'data': searchData,
			'setter': setSearchData
		}
	];
	
	var tableData = {
						'metadata': {
										'id': 'instructionSetsTable',
										'title': 'Instruction Sets',
										'columns':[
											{
												'id': 'name',
												'label': 'Name'
											},
											{
												'id': 'description',
												'label': 'Description'
											}
										]
									},
						'data': data
	}
	
	async function searchFunction(page = 0){
		let endpoint = '/instruction_sets/' + instructionSetId + '/variable_sets?limit=25'
		let qParam = window._getCommonFunction('buildSearchQuery')(searchData);
		if (qParam.length > 0){
			endpoint += '&q='+qParam;
		}
		let response = await CachedSessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			setData(response['json']['items']);
			if (response['json']['has_more'] == true){
				setTableforwardDisabled(false);
			}
			else{
				setTableforwardDisabled(true);
			}
			if (page>0){
				setTablebackDisabled(false);
			}
			else{
				setTablebackDisabled(true);
			}
		}
	}
	
	function selectFunction(data){
		setSelected(data);
		setViewDisabled(false);
		setEditDisabled(false);
		setDeleteDisabled(false);
		setValidateDisabled(false);
	}
	
	function deselectFunction(data){
		setViewDisabled(true);
		setEditDisabled(true);
		setDeleteDisabled(true);
		setValidateDisabled(true);
	}
	
	async function tableBack(){
		if (page > 0){
			let lastPage = page-1;
			setPage(lastPage);
			searchFunction(lastPage);
		}
	}
	
	async function tableForward(){
		let nextPage = page+1;
		setPage(nextPage);
		searchFunction(nextPage);
	}
	
	function addVariableSet(){
		window._getCommonFunction('navigate')('/instruction_sets/'+ instructionSetId +'/variable_sets/0/create');
	}
	
	function viewVariableSet(){
		window._getCommonFunction('navigate')('/instruction_sets/' + instructionSetId + '/variable_sets/' + selected['id'] +'/view');
	}
	
	function editVariableSet(){
		window._getCommonFunction('navigate')('/instruction_sets/' + instructionSetId + '/variable_sets/' + selected['id'] +'/edit');
	}
	
	function deleteVariableSet(){
		if(window.confirm("Are you sure you want to delete?")){
			
		}
	}
	
	function validateVariableSet(){
		console.log("VALIDATE");
	}
	
	var toolbarButtons = [
		{
			'id':'add', 
			'label': 'Create Variable Set',
			'_className': 'ToolbarButton', 
			'icon':faPlus, 
			fn: addVariableSet,
			disabled: addDisabled
		},
		{
			'id':'refresh', 
			'label': 'Refresh',
			'_className': 'ToolbarButton', 
			'icon':faRotate,
			fn: searchFunction,
		},
		{
			'id':'tableBack', 
			'label': 'Back',
			'_className': 'ToolbarButton', 
			'icon':faAngleLeft,
			fn: tableBack,
			disabled: tablebackDisabled
		},
		{
			'id':'tableForward', 
			'label': 'Forward',
			'_className': 'ToolbarButton', 
			'icon':faAngleRight,
			fn: tableForward,
			disabled: tableforwardDisabled
		},
		{
			'id':'view', 
			'label': 'View Variable Set',
			'_className': 'ToolbarButton', 
			'icon':faEye, 
			fn: viewVariableSet,
			disabled: viewDisabled
		},
		{
			'id':'edit', 
			'label': 'Edit Variable Set',
			'_className': 'ToolbarButton', 
			'icon':faPenToSquare, 
			fn: editVariableSet,
			disabled: editDisabled
		},
		{
			'id':'delete', 
			'label': 'Delete Variable Set',
			'_className': 'ToolbarButton', 
			'icon':faXmark, 
			fn: deleteVariableSet,
			disabled: deleteDisabled
		},
		{
			'id':'validate', 
			'label': 'Validate Variable Set',
			'_className': 'ToolbarButton', 
			'icon':faCircleCheck, 
			fn: validateVariableSet,
			disabled: validateDisabled
		}
	]
	
	useEffect(() => {
		searchFunction(0);
	},[]);
	
	
	return(
		<div className = 'ProjectsPage'>
			<div>
			<Banner bannerText="Variable Sets"/>
			<WorkArea>
				<ViewManySearchTable defaultSearchData={defaultSearchData} 
					searchFormData={searchFormData} 
					searchFunction={searchFunction} 
					toolbarButtons={toolbarButtons} 
					tableData={tableData}
					selectFunction={selectFunction}
					deselectFunction={deselectFunction}/>
			</WorkArea>
			</div>
		</div>
	)
	
	
}

export default VariableSetViewMany;