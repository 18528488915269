import './InstructionSetRunViewMany.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import { useState, useEffect } from "react";
import {SessionRequest, SessionDownload, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faEye, faFileInvoice, faFileLines, faXmark, faAngleRight, faAngleLeft, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function InstructionSetRunViewMany(props){
	
	const navigate = useNavigate();
	
	let {clientId, instructionSetId} = useParams();
	
	var defaultSearchData = {
								'name': '',
								'description': '',
								'status': '',
								'result': '',
								'client_id': clientId==0 ? '' : clientId
							}
	
	const [searchData, setSearchData] = useState(defaultSearchData);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [viewDisabled, setViewDisabled] = useState(true);
	const [tablebackDisabled, setTablebackDisabled] = useState(true);
	const [tableforwardDisabled, setTableforwardDisabled] = useState(true);
	const [logDisabled, setLogDisabled] = useState(true);
	const [docDisabled, setDocDisabled] = useState(true);
	const [cancelDisabled, setCancelDisabled] = useState(true);
	const [clientOptions, setClientOptions] = useState([]);
	const [page, setPage] = useState(0);
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			await setClientOptions(updatedClientOptions);
		}
	}
	
	var searchFormData = [
		{
			'metadata': {
							id: 'searchFormData',
							title: null,
							expandable: true,
							_className: 'SearchFormSection',
							fields: [
								{
									id: 'name',
									label: 'Name',
									placeholder: 'Name',
								},
								{
									id: 'description',
									label: 'Description',
									placeholder: 'Description'
								},
								{
									id: 'status',
									label: 'Status',
									placeholder: 'Status'
								},
								{
									id: 'result',
									label: 'Result',
									placeholder: 'Result'
								},
								{
									id: 'client_id',
									label: 'Client',
									placeholder: 'Client',
									type: 'select',
									selectOptions: clientOptions
								}
							]
						},
			'data': searchData,
			'setter': setSearchData
		}
	];
	
	var tableData = {
						'metadata': {
									'id': 'instructionSetsTable',
									'title': 'Instruction Sets',
									'columns':[
										{
											'id': 'name',
											'label': 'Name'
										},
										{
											'id': 'description',
											'label': 'Description'
										},
										
										{
											'id': 'status',
											'label': 'Status'
										},
										{
											'id': 'result',
											'label': 'Result'
										},
										{
											'id': 'client_id',
											'label': 'Client'
										},
										{
											'id': 'start_time',
											'label': 'Start Time'
										},
										{
											'id': 'end_time',
											'label': 'End Time'
										}
									]
								},
						'data': data
	}
	
	async function searchFunction(page=0){
		let offset = page==0 ? 0 : (page*25)-1;
		let endpoint = '/instruction_set_runs?limit=25&offset='+offset+'&order_by=start_time desc'
		let qParam = window._getCommonFunction('buildSearchQuery')(searchData);
		if (qParam.length > 0){
			endpoint += "&q="+qParam;
		}
		let response = await SessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			setData(response['json']['items']);
			if (response['json']['has_more'] == true){
				setTableforwardDisabled(false);
			}
			else{
				setTableforwardDisabled(true);
			}
			if (page>0){
				setTablebackDisabled(false);
			}
			else{
				setTablebackDisabled(true);
			}
		}
	}
	
	function selectFunction(data){
		setSelected(data);
		setViewDisabled(false);
		if (data['log_attachment_id'] != null){
			setLogDisabled(false);
		}
		else{
			setLogDisabled(true);
		}
		if (data['doc_attachment_id'] != null){
			setDocDisabled(false);
		}
		else{
			setDocDisabled(true);
		}
		if (data['status'] === 'WAITING' || 
			data['status'] === 'RUNNING' ||
			data['status'] === 'STARTED' ||
			data['status'] === 'SUBMITTED'){
			setCancelDisabled(false);
		}
		else{
			setCancelDisabled(true);
		}
	}
	
	function deselectFunction(data){
		setViewDisabled(true);
		setLogDisabled(true);
		setDocDisabled(true);
		setCancelDisabled(true);
	}
	
	async function tableBack(){
		if (page > 0){
			let lastPage = page-1;
			setPage(lastPage);
			searchFunction(lastPage);
		}
	}
	
	async function tableForward(){
		let nextPage = page+1;
		setPage(nextPage);
		searchFunction(nextPage);
	}
	
	function viewTestRun(){
		navigate('/instruction_set_runs/' + selected['id'] + '/view');
	}
	
	async function getLog(){
		let endpoint = '/attachments/'+ selected['log_attachment_id'] +'/download'
		let response = await SessionDownload(endpoint, 'GET',{},null);
	}
	
	async function getDocument(){
		let endpoint = '/attachments/'+ selected['doc_attachment_id'] +'/download'
		let response = await SessionDownload(endpoint, 'GET',{},null);
	}
	
	async function cancelTestRun(){
		if(window.confirm("Are you sure you want to cancel this run?")){
			let endpoint = '/instruction_set_runs/'+ selected['id'] +'/cancel'
			let response = await SessionRequest(endpoint);
			searchFunction();
		}
	}
	
	var toolbarButtons = [
		{
			'id':'refresh', 
			'label': 'Refresh',
			'_className': 'ToolbarButton', 
			'icon':faRotate,
			fn: searchFunction,
		},
		{
			'id':'tableBack', 
			'label': 'Back',
			'_className': 'ToolbarButton', 
			'icon':faAngleLeft,
			fn: tableBack,
			disabled: tablebackDisabled
		},
		{
			'id':'tableForward', 
			'label': 'Forward',
			'_className': 'ToolbarButton', 
			'icon':faAngleRight,
			fn: tableForward,
			disabled: tableforwardDisabled
		},
		{
			'id':'view', 
			'label': 'View Test Run',
			'_className': 'ToolbarButton', 
			'icon':faEye,
			fn: viewTestRun,
			disabled: viewDisabled
		},
		{
			'id':'getLog', 
			'label': 'Get Test Run Log',
			'_className': 'ToolbarButton', 
			'icon':faFileLines, 
			fn: getLog,
			disabled: logDisabled
		},
		{
			'id':'getDoc', 
			'label': 'Get Test Run Document',
			'_className': 'ToolbarButton', 
			'icon':faFileInvoice, 
			fn: getDocument,
			disabled: docDisabled
		},
		{
			'id':'cancel', 
			'label': 'Cancel Test Run',
			'_className': 'ToolbarButton', 
			'icon':faXmark, 
			fn: cancelTestRun,
			disabled: cancelDisabled
		},
	]
	
	useEffect(() => {
			getClients();
			//searchFunction
		},[]);
	
	return(
		<div className = 'InstructionSetRunViewMany'>
			
			<Banner icon={faVial} bannerText="Test Runs"/>
			<WorkArea>
				<ViewManySearchTable defaultSearchData={defaultSearchData} 
					searchFormData={searchFormData} 
					searchFunction={searchFunction} 
					toolbarButtons={toolbarButtons} 
					tableData={tableData}
					selectFunction={selectFunction}
					deselectFunction={deselectFunction}/>
			</WorkArea>
			
		</div>
	)
	
	
}

export default InstructionSetRunViewMany;