import './Menu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faHouse, faBell } from '@fortawesome/free-solid-svg-icons';

function Menu(props){
	
	const id = props.id;
	const menuItems = props.menuItems || new Array();
	const visible = props.visible;
	
	let classes = 'Menu';
	if (visible){
		classes = classes + " MenuVisible";
	}
	
	return(
		<div className={classes} id={id} style={{top: props.top, left: props.left, bottom: props.bottom, right: props.right, width:props.width}}>
			{menuItems.map((item) => <MenuItem key={id + '_' + item['label']} id={id + '_' + item['label'].replace(' ', '_')} label={item['label']} icon={item['icon']}/>)}
		</div>
	);
}

function MenuItem(props){
	
	return(
		<div className = 'MenuItem' id={props.id}>
			{props.icon != null &&
			<div className = 'MenuItemIcon'>
				<FontAwesomeIcon icon={props.icon} style={{height:'20px'}} inverse/>
			</div>
			}
			<div className='MenuItemLabel'>
				{props.label}
			</div>
		</div>
	)
	
	
}

export default Menu;