import './InstructionSetViewSingle.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleForm from '../../Views/ViewEditSingleForm.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import ViewManySearchTableModal from '../../Views/ViewManySearchTableModal.js';

import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskVial, faUser, faMagnifyingGlass,faSquarePlus, faArrowUp, faArrowDown, faPlus,faFileCode,faTableList, faPlay, faXmark, faPenToSquare, faPlusSquare, faSearch, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function InstructionSetViewSingle(props){
	
	const navigate = useNavigate();
	
	let {instructionSetId, mode} = useParams();
	
	var defaultData = {
							'name': '',
							'description': '',
							'start_date': '',
							'end_date': '',
							'category': '',
							'client': '',
							'executable_flag': '',
							'@Module': '',
							'@Business Scenario': '',
							'@Test Case Code': '',
							'@Test Case Category': ''
							}
	
	const [data, setData] = useState(defaultData);
	const [instructionData, setInstructionData] = useState({'instruction_sets': []});
	const [clientOptions, setClientOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [moduleOptions, setModuleOptions] = useState([]);
	const [selectedInstructionSetIndex, setSelectedInstructionSetIndex] = useState({});
	const [modalSearchData, setModalSearchData] = useState({});
	const [modalData, setModalData] = useState([]);
	const [selectedModalData, setSelectedModalData] = useState(null);
	const [moveInstructionSetUpDisabled, setMoveInstructionSetUpDisabled] = useState(true);
	const [moveInstructionSetDownDisabled, setMoveInstructionSetDownDisabled] = useState(true);
	const [deleteInstructionSetDisabled, setDeleteInstructionSetDisabled] = useState(true);
	const [modalAddInstructionSetDisabled, setModalAddInstructionSetDisabled] = useState(true);
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': null, 'displayValue': null});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			await setClientOptions(updatedClientOptions);
		}
	}
	
	async function getCategories(){
		let endpoint = '/instruction_set_categories?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedCategoryOptions = new Array();
			updatedCategoryOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let category = new Object();
				category['value'] = j['items'][i]['name'];
				category['displayValue'] = j['items'][i]['name'];
				updatedCategoryOptions.push(category);
			}
			await setCategoryOptions(updatedCategoryOptions);
		}
	}
	
	async function getModules(){
		let endpoint = '/instruction_set_modules?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedModuleOptions = new Array();
			updatedModuleOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let module = new Object();
				module['value'] = j['items'][i]['name'];
				module['displayValue'] = j['items'][i]['name'];
				updatedModuleOptions.push(module);
			}
			await setModuleOptions(updatedModuleOptions);
		}
	}
	
	async function getInstructionSet(instructionSetId){
		try{
			let endpoint = '/instruction_sets/' + instructionSetId + '?&expand=attributes,instructions,params';
			let response = await SessionRequest(endpoint);
			if (response['status_code'] == 200){
				let j = await response['json'];
				setData(j);
				let instructions = j['instructions'];
				let updatedInstructionData = new Object();
				updatedInstructionData['instruction_sets'] = new Array();
				for (let i=0;i<instructions.length;i++){
					let id = instructions[i]['id'];
					let label = instructions[i]['name'];
					updatedInstructionData['instruction_sets'].push({'id':id, 'label': label, 'data': instructions[i]});
				}
				setInstructionData(updatedInstructionData);
			}
		}
		catch (err) {}
		
	}
	
	useEffect(() => {
		getClients();
		getModules();
		getCategories();
		if (mode === 'edit' || mode==='view') {
				getInstructionSet(instructionSetId);
			}
		},[]);
	
	let disableField = mode=='view' ? true : false;
	
	function addChildInstructionSet(){
		if (selectedModalData != null){
			let id = selectedModalData['id'];
			let label = selectedModalData['name'];
			for (let i=0;i<instructionData['instruction_sets'].length;i++){
				let currentInstructionSet = instructionData['instruction_sets'][i];
				if (currentInstructionSet['id'] == id){
					return;
				}
			}
			let newInstruction = new Object();
			newInstruction['method_name'] = 'instruction_set';
			newInstruction['name'] = selectedModalData['name'];
			newInstruction['description'] = selectedModalData['description'];
			newInstruction['params'] = [{
										'method_name':'instruction_set', 
										'method_arg_name':'instruction_set_id',
										'value': selectedModalData['id']
										}]
			let updatedInstructionData = new Object();
			updatedInstructionData['instruction_sets'] = instructionData['instruction_sets'].slice();
			updatedInstructionData['instruction_sets'].push({'id':id, 'label': label, 'data': newInstruction});
			setInstructionData(updatedInstructionData);
		}
	}
	
	function moveInstructionSetUp(){
		if (selectedInstructionSetIndex > 0){
			let updatedInstructionData = new Object();
			updatedInstructionData['instruction_sets'] = instructionData['instruction_sets'].slice();
			let selectedVarMap = updatedInstructionData['instruction_sets'].splice(selectedInstructionSetIndex,1)[0];
			updatedInstructionData['instruction_sets'].splice(selectedInstructionSetIndex-1,0,selectedVarMap);
			setInstructionData(updatedInstructionData);
			setSelectedInstructionSetIndex(selectedInstructionSetIndex-1);
		}
	}
	
	function moveInstructionSetDown(){
		if (selectedInstructionSetIndex < instructionData['instruction_sets'].length-1 && selectedInstructionSetIndex >= 0){
			let updatedInstructionData = new Object();
			updatedInstructionData['instruction_sets'] = instructionData['instruction_sets'].slice();
			let selectedVarMap = updatedInstructionData['instruction_sets'].splice(selectedInstructionSetIndex,1)[0];
			updatedInstructionData['instruction_sets'].splice(selectedInstructionSetIndex+1,0,selectedVarMap);
			setInstructionData(updatedInstructionData);
			setSelectedInstructionSetIndex(selectedInstructionSetIndex+1);
		}
	}
	
	function removeInstructionSet(){
		if (instructionData['instruction_sets'].length>0 && selectedInstructionSetIndex >= 0){
			let updatedInstructionData = new Object();
			updatedInstructionData['instruction_sets'] = instructionData['instruction_sets'].slice();
			updatedInstructionData['instruction_sets'].splice(selectedInstructionSetIndex,1);
			setInstructionData(updatedInstructionData);
			setSelectedInstructionSetIndex(-1);
		}
	}
	
	function searchInstructionSet(){
		document.getElementById('instructionSetSearchModal').classList.toggle('ModalHidden');
	}
	
	
	var toolbarButtons = mode=='view' ? [] : [
		{
			'id':'add', 
			'label': 'Add Instruction Set',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faMagnifyingGlass, 
			'fn': searchInstructionSet
		},
		{
			'id':'moveUp', 
			'label': 'Move Instruction Set Up',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faArrowUp, 
			'fn': moveInstructionSetUp,
			'disabled': moveInstructionSetUpDisabled
		},
		{
			'id':'moveDown', 
			'label': 'Move Instruction Set Down',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faArrowDown, 
			'fn': moveInstructionSetDown,
			'disabled': moveInstructionSetDownDisabled
		},
		{
			'id':'delete', 
			'label': 'Remove Instruction Set',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faXmark, 
			'fn': removeInstructionSet,
			'disabled': deleteInstructionSetDisabled
		}
	]
	
	function ChildInstructionSetSelectFunction(index, itemData){
		setSelectedInstructionSetIndex(index);
		if (mode != 'view'){
			setMoveInstructionSetUpDisabled(false);
			setMoveInstructionSetDownDisabled(false);
			setDeleteInstructionSetDisabled(false);
		}
	}
	
	var formData = [
						{
							'metadata':	{
									id: 'formData',
									title: null,
									expandable: false,
									_className: 'SearchFormSection',
									fields: [
										{
											id: 'name',
											label: 'Name',
											disabled: disableField
										},
										{
											id: 'description',
											label: 'Description',
											disabled: disableField
										},
										{
											id: 'category',
											label: 'Category',
											disabled: disableField,
											type: 'select',
											selectOptions: categoryOptions
										},
										{
											id: 'client_id',
											label: 'Client',
											disabled: disableField,
											type: 'select',
											selectOptions: clientOptions
										},
										{
											id: '@Module',
											label: 'Module',
											disabled: disableField,
											type: 'select',
											selectOptions: moduleOptions
										},
										{
											id: '@Business Scenario',
											label: 'Business Scenario',
											disabled: disableField
										},
										{
											id: '@Test Case Code',
											label: 'Test Case Code',
											disabled: disableField
										},
										{
											id: '@Test Case Category',
											label: 'Test Case Category',
											disabled: disableField
										},
										{
											id: 'executable_flag',
											label: 'Executable Flag',
											type: 'checkbox',
											disabled: disableField
										},							
									]
								},
						'data': data,
						'setter': setData
					},
					{
								'metadata': {
									id: 'childInstructionSetFormSection',
									title: 'Instructions',
									expandable: true,
									_className: 'FormSection',
									fields: [
										{
											id: 'instruction_sets',
											label: 'Instructions',
											type: 'multisearchbox',
											toolbarButtons: toolbarButtons,
											itemSelectFunction: ChildInstructionSetSelectFunction
										},
									]
								},
							'data': instructionData,
							'setter': setInstructionData
					}
				]
	
	function preProcessData(data){
		let processedData = new Object();
		processedData['attributes'] = new Array();
		processedData['instructions'] = new Array();
		for (let i=0; i< formData[0]['metadata']['fields'].length; i++){
			let field = formData[0]['metadata']['fields'][i]['id'];
			if (field.substring(0,1) == '@'){
				let attr = {'attr_name': field.substring(1) , 'value': data[field]};
				processedData['attributes'].push(attr);
			}
			else if (field === 'attributes' || field === 'instructions'){
				continue;
			}
			else{
				processedData[field] = data[field];
			}
		}
		for (let i=0; i < instructionData['instruction_sets'].length; i++){
			let instruction = instructionData['instruction_sets'][i]['data'];
			instruction['seq_num'] = i;
			processedData['instructions'].push(instruction);
		}
		return processedData;
	}
	
	function submit(){
		if (mode=='edit'){
			let endpoint = '/instruction_sets/' + instructionSetId + '?&expand=attributes,instructions,params';
			if(window.confirm('Are you sure you want to save changes to this instruction set?')){
				let processedData = preProcessData(data);
				SessionRequest(endpoint, 'PATCH', {}, processedData);
				navigate('/instruction_sets/view');
			}
		}
		if (mode=='create'){
			let endpoint = '/instruction_sets';
			if(window.confirm('Are you sure you want to create this instruction set?')){
				let processedData = preProcessData(data);
				SessionRequest(endpoint, 'POST', {}, processedData);
				navigate('/instruction_sets/view');
			}
		}
	}
	
	function close(){
		navigate(-1);
	}
	
	let formButtons = mode=='view' ? [{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	] : [
		{
			'id':'submitButton',
			'label': 'Submit',
			'_className': 'TextButton',
			'fn': submit
		},{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	/**** MODAL ****/
	
	var modalTableData = {
						'metadata': {
							'id': 'instructionSetModalTable',
							'title': 'Instruction Sets',
							'columns':[
								{
									'id': 'name',
									'label': 'Name'
								}
							]
						},
						'data': modalData
					}
	
	let modalSearchFormData = [{
			'metadata': {
						id: 'searchFormData',
						title: null,
						expandable: true,
						_className: 'SearchFormSection',
						fields: [
							{
								id: 'name',
								label: 'Name',
								placeholder: 'Name',
							}
						]
					},
			'data': modalSearchData,
			'setter': setModalSearchData
		}]

	async function modalSearchFunction(){
		let endpoint = '/instruction_sets?limit=25&order_by=name&expand=instructions,params'
		let qParam = window._getCommonFunction('buildSearchQuery')(modalSearchData);
		endpoint += "&q=active_flag='Y' and"+qParam;
		let response = await SessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			setModalData(response['json']['items']);
		}
	}
	
	async function modalSelectFunction(data){
		setSelectedModalData(data);
		setModalAddInstructionSetDisabled(false);
	}
	
	async function modalDeselectFunction(data){
		setSelectedModalData(null);
		setModalAddInstructionSetDisabled(true);
	}
	
	var modalToolbarButtons = [
		{
			'id':'pull_instruction_set', 
			'label': 'Add Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faSquarePlus, 
			'fn': addChildInstructionSet,
			'disabled': modalAddInstructionSetDisabled
		}
	]

		
	
	return(
		<div className = 'InstructionSetViewSingle'>
			<Banner icon={faFlaskVial} bannerText="Instruction Sets"/>
			<WorkArea>
				<ViewEditSingleForm title='Instruction Set'
					formData = {formData}
					data={data}
					dataSetter={setData}
					formButtons={formButtons} />
			</WorkArea>
			<ViewManySearchTableModal id='instructionSetSearchModal'
					searchFormData={modalSearchFormData} 
					searchFunction={modalSearchFunction} 
					toolbarButtons={modalToolbarButtons} 
					tableData={modalTableData}
					selectFunction={modalSelectFunction}
					deselectFunction={modalDeselectFunction}/>
		</div>
	)
	
	
}

export default InstructionSetViewSingle;