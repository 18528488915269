import './App.css';
import { useState, useEffect } from "react";
import InitializeEventHandler from './Events/EventHandler.js';
import InitializeCommonFunctions from './Events/CommonFunctions.js';
import PageSwitch from './Events/PageManager.js';
import Container from './Components/Container.js';
import TopNav from './Components/TopNav.js';
import PageContainer from './Components/PageContainer.js';
import Footer from './Components/Footer.js';
import Menu from './Components/Menu.js';
import SignInModal from './Views/SignInModal.js';
import { faUser, faFlaskVial, faVial, faMap, faPeopleGroup, faUserTie, faPenRuler, faDiagramProject, faUserEdit, faTasks, faUserClock, faUserCog, faUserFriends, faShop, faUserLock, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import {SessionRequest, StartSession, EndSession, GetAuthDetails, RefreshCache, ValidatePermission, LoggedIn} from './Events/Requester.js'
import {
	Route,
	Switch,
	withRouter,
	useNavigate,
	useLocation
} from "react-router-dom";


window.onerror = function(msg, url, line, col, error){
	console.log(msg);
	console.log(url);
	console.log(line);
	console.log(col);
	console.log(error);
	var suppressErrorAlert = true;
	return suppressErrorAlert;
}

InitializeEventHandler();
InitializeCommonFunctions();

function closeMenus(e){
	let menuElements = document.getElementsByClassName("MenuVisible");
	for (var i=0; i < menuElements.length; i++){
		menuElements[i].classList.remove("MenuVisible");
	}
}

function toggleNavigator(e){
	var element = document.getElementById('navigatorMenu');
	closeMenus(e);
	element.classList.toggle("MenuVisible");
	return true;
}

function toggleAccountMenu(e){
	var element = document.getElementById('accountMenu');
	closeMenus(e);
	element.classList.toggle("MenuVisible");
	return true;
}

window._setEventHandle('topnavitem_menu', 'click', toggleNavigator);
window._setEventHandle('topnavitem_user', 'click', toggleAccountMenu);
window._setEventHandle('root', 'click', closeMenus);

// App
function App() {
	
	const navigate = useNavigate();
	const location = useLocation();
	
	
	function RefreshPage(){
		navigate("/refresh?path=" + location.pathname, {replace:true});
	}
	
	window._setEventHandle('topnavitem_home', 'click', () => {navigate('landing')});
	window._setEventHandle('topnavitem_refresh', 'click', () => {RefreshCache();RefreshPage();});
	window._setEventHandle('topnavitem_back', 'click', () => {navigate(-1);});
	window._setEventHandle('topnavitem_forward', 'click', () => {navigate(1);});
	window._setEventHandle('navigatorMenu_Clients', 'click', () => {navigate("/clients/view")});
	window._setEventHandle('navigatorMenu_Instruction_Sets', 'click', () => {navigate("/instruction_sets/view")});
	window._setEventHandle('navigatorMenu_Test_Runs', 'click', () => {navigate('/instruction_set_runs/0/0/view')});
	window._setEventHandle('navigatorMenu_Variable_Maps', 'click', () => {navigate('/variable_maps/view')});
	//window._setEventHandle('navigatorMenu_Projects', 'click', () => {navigate('/projects/view')});
	window._setEventHandle('topnavitem_logo', 'click', () => {navigate("/landing")});
	window._setEventHandle('accountMenu_Sign_In', 'click', () => {showSignInModal();});
	window._setEventHandle('accountMenu_Sign_Out', 'click', () => {SignOut();});
	
	const [menuItems, setMenuItems] = useState([]);
	const [accountItems, setAccountItems] = useState([]);
	const [authDetails, setAuthDetails] = useState({});
	
	
	
	async function UpdateNavBar(){
		let auth = await GetAuthDetails();
		setAuthDetails(auth);
		let accountItemsCopy = new Array();
		let menuItemsCopy = new Array();
		if (LoggedIn()){
			accountItemsCopy = [
						{"label":"Profile", "icon":faUserEdit},
						{"label":"My Team", "icon":faPeopleGroup},
						{"label":"Sign Out", "icon":faUserLock},
						{"label":"Help", "icon":faCircleQuestion},
					];
					if (ValidatePermission('Client:Retrieve')){
						menuItemsCopy.push({"label":"Clients", "icon":faShop});
					}
					if (ValidatePermission('InstructionSet:Retrieve')){
						menuItemsCopy.push({"label":"Instruction Sets", "icon": faFlaskVial});
					}
					if (ValidatePermission('VariableMap:Retrieve')){
						
						menuItemsCopy.push({"label":"Variable Maps", "icon": faMap});
					}
					if (ValidatePermission('InstructionSetRun:Retrieve')){
						menuItemsCopy.push({"label":"Test Runs", "icon": faVial});
					}
					//menuItemsCopy.push({"label":"Projects", "icon":faPenRuler});
		}
		else{
			accountItemsCopy = [
				{"label":"Sign In", "icon":faUserLock},
			]
		}
		setAccountItems(accountItemsCopy);
		setMenuItems(menuItemsCopy);
	}
	
	function showSignInModal(){
		document.getElementById('signInModal').classList.toggle('ModalHidden');
	}
	
	async function SignInCallback(){
		UpdateNavBar();
		showSignInModal();
	}
	async function SignOut(){
		EndSession();
		UpdateNavBar();
		navigate("/landing")
	}
	
	useEffect(()=> {
		UpdateNavBar();
	},[])
	
	/*
	var menuItems = [
						{"label":"Clients", "icon":faShop},
						//{"label":"Projects", "icon":faPenRuler},
						//{"label":"Teams", "icon":faUserFriends},
						//{"label":"Resources", "icon":faUserCog},
						//{"label":"Tasks", "icon":faTasks},
						{"label":"Test", "icon": faFlaskVial},
						{"label":"Variable Maps", "icon": faMap},
						{"label":"Test Runs", "icon": faVial},
						//{"label":"Config", "icon": faGear},
						//{"label":"Robo", "icon": faRobot},
						//{"label":"Security", "icon": faShield}
					];
	var accountItems = [
						{"label":"Profile", "icon":faUserEdit},
						{"label":"My Team", "icon":faPeopleGroup},
						{"label":"Sign Out", "icon":faUserLock},
						{"label":"Help", "icon":faCircleQuestion},
					];
	*/
	
	return (
		<Container containerType='App'>
			<Menu id='navigatorMenu' top='60px' menuItems={menuItems}/>
			<Menu id='accountMenu' top='60px' right='0px' menuItems={accountItems}/>
			<Container containerType='Page'>
				<PageContainer id='pageContainer'>
					<PageSwitch/>
				</PageContainer>
			</Container>
			<Container containerType='TopNav'>
				<TopNav authDetails={authDetails}/>
			</Container>
			
			<Container containerType='Footer'>
				<Footer />
			</Container>
			<SignInModal id='signInModal' signInCallback={SignInCallback}/>
		</Container>
	
	
  );
}

export default App;
