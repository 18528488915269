function InitializeEventHandler() {
	
	if (!document._eventHandlerInitialized){
		
		var eventMapper = new Object();
		var commonFunctions = new Object();
		var defaultBeforeFunctions = new Object();
		var defaultAfterFunctions = new Object();
		
		function handleEvent(e){
			let element = e.target;
			let eventType = e.type;
			let handled = false;
			while (!handled){
				let elementId = element.id;
				handled = getEventHandle(elementId, eventType)(e);
				element = element.parentElement;
				if (element == undefined){
					break;
				}
			}
			if (handled){
				e.preventDefault();
			}
			handleDefaultAfterFunctions(e);
		}
		
		function handleDefaultBeforeFunctions(e){
			for (let fnName in defaultBeforeFunctions){
				try{
					defaultBeforeFunctions[fnName](e)
				} catch (err) {}
			}
		}
		
		function handleDefaultAfterFunctions(e){
			for (let fnName in defaultAfterFunctions){
				try{
					defaultAfterFunctions[fnName](e)
				} catch (err) {}
			}
		}
		
		function setDefaultAfterFunction(fnName, fn){
			defaultAfterFunctions[fnName] = fn;
		}
		
		function setDefaultBeforeFunction(fnName, fn){
			defaultBeforeFunctions[fnName] = fn;
		}
		
		function setCommonFunction(fnName, fn){
			commonFunctions[fnName] = fn;
		}
		
		function getCommonFunction(fnName){
			let fn = commonFunctions[fnName];
			if (fn == undefined || fn == null){
				return () => {return null;}
			}
			return fn;
		}
		
		function setEventHandle(elementId, eventType, fn){
			if (eventMapper[elementId] == undefined || typeof(eventMapper[elementId]) != 'object'){
				eventMapper[elementId] = new Object();
			}
			eventMapper[elementId][eventType] = fn;
		}
		
		function getEventHandle(elementId, eventType){
			let elementMap = eventMapper[elementId];
			if (elementMap != undefined){
				let eventHandle = eventMapper[elementId][eventType];
				if (eventHandle != undefined) {
					return eventHandle;
				}
			}
			return () => {return false;}
		}
		
		window.addEventListener('click', handleEvent, true);
		window.addEventListener('contextmenu', handleEvent, true);
		window.addEventListener('dblclick', handleEvent, true);
		window.addEventListener('keydown', handleEvent, true);
		window.addEventListener('keyup', handleEvent, true);
		window.addEventListener('mousedown', handleEvent, true);
		window.addEventListener('mouseup', handleEvent, true);
		
		window._setEventHandle = function(elementId, eventType, fn){ setEventHandle(elementId, eventType, fn); };
		window._getEventHandle = function(elementId, eventType){ return getEventHandle(elementId, eventType); };
		window._setDefaultBeforeFunction = function(fnName, fn){ setDefaultBeforeFunction(fnName, fn); };
		window._setDefaultAfterFunction = function(fnName, fn){ setDefaultAfterFunction(fnName, fn); };
		window._setCommonFunction = function(fnName, fn){ return setCommonFunction(fnName, fn); };
		window._getCommonFunction = function(fnName){ return getCommonFunction(fnName); };
		
	}
	document._eventHandlerInitialized = true;
}

export default InitializeEventHandler;