import './ViewEditSingleForm.css'
import { useState } from "react";
import {Form} from '../Components/Form.js';

function ViewEditSingleForm(props){
	
	var formData = props.formData;
		
	return(
		<div className = 'ViewEditSingleForm'>
			<Form id = 'modifyForm' 
				title={props.title} 
				sections = {formData} 
				buttons= {props.formButtons} 
				_className = 'SearchForm'/>
		</div>
	)
}

export default ViewEditSingleForm;