import './VariableMapViewSingle.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleForm from '../../Views/ViewEditSingleForm.js';
import ViewEditSingleFormTable from '../../Views/ViewEditSingleFormTable.js';
import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faUser, faPlus,faFileCode,faTableList, faPlay, faXmark, faPenToSquare, faPlusSquare, faSearch, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function VariableMapViewSingle(props){
	
	const navigate = useNavigate();
	
	let {varMapId, mode} = useParams();
	
	var defaultData = {
							'name': '',
							'description': '',
							'client_id': null
						}
	
	const [data, setData] = useState(defaultData);
	const [mapItems, setMapItems] = useState([]);
	const [selectedId, setSelectedId] = useState(0);
	const [addDisabled, setAddDisabled] = useState(false);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [clientOptions, setClientOptions] = useState([]);
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': null, 'displayValue': null});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			setClientOptions(updatedClientOptions);
		}
	}
	
	async function getVariableMap(varMapId){
		try{
			let endpoint = '/variable_maps/' + varMapId + '?expand=map_items&order_by=map_items.id';
			let response = await SessionRequest(endpoint);
			if (response['status_code'] == 200){
				let j = await response['json'];
				setData(j);
				setMapItems(j['map_items']);
			}
		}
		catch (err) {}
		
	}
	
	useEffect(() => {
		getClients();
		if (mode=='view' || mode=='edit'){
			getVariableMap(varMapId)
		}
		},[]);
	
	let disableField = mode=='view' ? true : false;
	
	var formData = [
						{
							'metadata':	{
									id: 'formData',
									title: null,
									expandable: false,
									_className: 'SearchFormSection',
									fields: [
										{
											id: 'name',
											label: 'Name',
											disabled: disableField
										},
										{
											id: 'description',
											label: 'Description',
											disabled: disableField
										},
										{
											id: 'client_id',
											label: 'Client',
											type: 'select',
											selectOptions: clientOptions,
											disabled: disableField
										},					
									]
								},
						'data': data,
						'setter': setData
					}
				]

	function selectFunction(index, data){
		setSelectedId(index);
		setDeleteDisabled(false);
	}
	
	function submit(){
		if(window.confirm('Are you sure you want to save changes to this variable map?')){
			let endpoint, method;
			if (mode == 'create'){
				endpoint = '/variable_maps';
				method = 'POST';
			}
			if (mode == 'edit'){
				endpoint = '/variable_maps/' + varMapId;
				method = 'PATCH';
			}
			try{
				let varMapObject = new Object();
				for (let field in data){
					if (field == 'name' && data[field].length == 0){
						throw 'Name is mandatory.';
					}
					if (field == 'client_id' && (data[field] == null || data[field].length == 0)){
						throw 'Client is mandatory.';
					}
					if (field == 'map_items') { continue; }
					varMapObject[field] = data[field];
				}
				let keyNames = new Array();
				for (let i=0;i<mapItems.length;i++){
					let key = mapItems[i]['key'];
					if (key == null || key.length == 0){
						throw 'Map items must have a key.'
					}
					else if (keyNames.includes(key)){
						throw 'Map item keys must be unique.'
					}
					keyNames.push(key)
				}
				varMapObject['map_items'] = mapItems.slice();
				SessionRequest(endpoint, method, {}, varMapObject);
				navigate('/variable_maps/view');
			}
			catch (err) {alert(err)};
		}
	}
	
	function close(){
		navigate(-1);
	}
	
	function addItem(){
		let currentMapItems = mapItems.slice();
		currentMapItems.push({
								'key': '',
								'value': '',
								'runtime_var_flag': 'N',
								'sensitive_flag': 'N',
							})
		setMapItems(currentMapItems);
	}
	
	function updater(index, field, val){
		let currentMapItems = mapItems.slice();
		currentMapItems[index][field] = val;
		setMapItems(currentMapItems);
	}
	
	async function deleteItem(){
		let currentMapItems = mapItems.slice();
		currentMapItems.splice(selectedId,1);
		let inputBoxes = document.getElementsByClassName('InputBox');
		await setMapItems(currentMapItems);
		for (let i=0;i<inputBoxes.length;i++){
			if (inputBoxes[i].attributes['value'] != undefined){
				inputBoxes[i].value = inputBoxes[i].attributes['value'].value;
			}
		}
	}
	
	let formButtons = mode=='view' ? [{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	] : [
		{
			'id':'submitButton',
			'label': 'Submit',
			'_className': 'TextButton',
			'fn': submit
		},{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	var toolbarButtons = mode=='view' ? null :[
		{
			'id':'add', 
			'label': 'Add Map Item',
			'_className': 'ToolbarButton', 
			'icon':faPlus, 
			fn: addItem,
			disabled: addDisabled
		},
		{
			'id':'delete', 
			'label': 'Delete Map Item',
			'_className': 'ToolbarButton', 
			'icon':faXmark, 
			fn: deleteItem,
			disabled: deleteDisabled
		}
	]
	
	var tableMetadata = {
			'id': 'variableMapItems',
			'title': 'Instruction Sets',
			'columns':[
				{
					'id': 'key',
					'label': 'Key',
					'disabled': disableField
				},
				{
					'id': 'value',
					'label': 'Value',
					'disabled': disableField
				},
				{
					'id': 'runtime_var_flag',
					'label': 'Runtime Variable',
					'type': 'checkbox',
					'disabled': disableField
				},
				{
					'id': 'sensitive_flag',
					'label': 'Sensitive',
					'type': 'checkbox',
					'disabled': disableField
				},
				
			]
		}
	
	var tableData = {
		'metadata': tableMetadata,
		'data': mapItems
	}
	
	return(
		<div className = 'VariableMapViewSingle'>
			<Banner icon={faMap} bannerText="Variable Map"/>
			<WorkArea>
				<ViewEditSingleFormTable title='Variable Map'
					formData = {formData}
					TableData={tableData}
					dataSetter={setData}
					formButtons={formButtons} 
					toolbarButtons={toolbarButtons}
					selectFunction={selectFunction}
					updater={updater}/>
			</WorkArea>
		</div>
	)
	
	
}

export default VariableMapViewSingle;