import './Footer.css';
import Container from './Container.js';
import logo from './novamoduslogo.png';
import {IconButton, Clickable} from './Button.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faHouse, faBell } from '@fortawesome/free-solid-svg-icons';


function Footer(props){
	
	return (
		<div className='Footer'>
			
		</div>
		);
}


export default Footer;