import './SignInModal.css'
import Modal from '../Components/Modal.js';
import { Form } from '../Components/Form.js';
import { useState } from "react";
import { StartSession } from '../Events/Requester.js';

function SignInModal(props){
	
	var defaultData = {'username': '',
					'password': ''}
	
	const [data, setData] = useState(defaultData);
	
	var signInFormData = [
		{'metadata': {
						id: 'signInFormSection',
						title: 'Sign In',
						fields: [
							{
								id: 'username',
								label: 'Username',
								placeholder: 'Username',
							},
							{
								id: 'password',
								label: 'Password',
								placeholder: 'Password',
								type: 'password'
							},
						]
					},
		'data': data,
		'setter': setData
		}
	];
	
	async function SignIn(){
		const sessionCreated = await StartSession(data['username'], data['password']);
		if (sessionCreated){
			document.getElementById("modalSignInForm_signInFormSection_password_input").value = "";
			props.signInCallback();
			window._getCommonFunction("navigate")("landing");
		}
		else{
			alert("Invalid credentials. Please try again.");
		}
	}
	
	var formButtons = [
		{
			'id':'signInButton', 
			'label':'Sign In', 
			'_className': 'SignInFormButton DefaultButton', 
			'fn':SignIn
		}
	]
	
	return(
		<Modal id={props.id} _className="SignInModal">
			<div>
				<Form id = 'modalSignInForm' 
					expandable={false} 
					sections = {signInFormData} 
					buttons= {formButtons} 
					_className = 'SignInForm'/>
			</div>
		</Modal>
	)
}

export default SignInModal;