import './Flowchart.css'
import { useState, useEffect } from "react";

function Flowchart(props){
	
	return(
		<div className = 'FlowchartCanvas'>
			<svg id={props.id} height="100%" width="100%">
				<FlowchartComponent/>
			</svg>
		</div>
	)
}

function FlowchartComponent(){
	
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);
	
	
	return(
			<g x={x} y={y}>
			
				<text x={x} y={y} text-anchor='middle'>TEST</text>
				<rect id='rect' x={x} y={y} rx="30" ry="30" width="200" height="100"
					style={{fill:'green',stroke:'black',strokeWidth:1,opacity:0.5}}>
				</rect>
				<text textAnchor='middle' alignmentBaseline='central'>TEST</text>
			</g>
	)
}

export default Flowchart;

