import './Modal.css';

function Modal(props){
	
	function hideModal(e){
		if (e.target.classList.contains('ModalOverlay')){
			e.target.classList.add('ModalHidden');
		}
	}
	
	return (
			<div id={props.id} className='ModalOverlay ModalHidden' onClick={hideModal}>
				<div className={props._className || 'Modal'}>
					{props.children}
				</div>
			</div>
		);
}

export default Modal;