function InitializeCommonFunctions(){
	
	function addStyles(_className, styles){
		const styleElement = document.createElement('style');
		styleElement.type = 'text/css';
		styleElement.innerHTML = '.' + _className + '{' + styles + '}';
		document.getElementsByTagName('head')[0].appendChild(styleElement);
	}
	window._setCommonFunction('addStyles', addStyles);
	
	function buildSearchQuery(searchData){
		let searchVal;
		let qParam = '';
		for (let searchField in searchData){
			searchVal = searchData[searchField];
			if (typeof(searchVal) == 'string' && searchVal.length > 0){
				if (qParam.length == 0){
					qParam = searchField + " like '%" + searchVal + "%'";
				}
				else{
					qParam += " and " + searchField + " like '%" + searchVal + "%'";
				}
			}
		}
		return qParam;
	}
	window._setCommonFunction('buildSearchQuery', buildSearchQuery);


}

export default InitializeCommonFunctions;