import './TopNav.css';
import logo from './novamoduslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faListAlt, faBars, faHouse, faBell, faAngleRight, faAngleLeft, faRotate } from '@fortawesome/free-solid-svg-icons';

function TopNav(props){
	
	return (
		<div className='TopNav'>
			{props.authDetails['user_id'] !== undefined && <TopNavItem id='topnavitem_menu' icon={faBars} float='left'/>}
			<TopNavLogo id='topnavitem_logo'/>
			
			<TopNavItem id='topnavitem_user' icon={faUser} float='right'/>
			
			<TopNavItem id='topnavitem_forward' icon={faAngleRight} float='right'/>
			
			<TopNavItem id='topnavitem_back' icon={faAngleLeft} float='right'/>
			
			<TopNavItem id='topnavitem_refresh' icon={faRotate} float='right'/>
			{props.authDetails['user_id'] !== undefined && <TopNavItem id='topnavitem_notif' icon={faBell} float='right'/>}
			{props.authDetails['user_id'] !== undefined && <TopNavItem id='topnavitem_todo' icon={faListAlt} float='right'/>}
			{props.authDetails['user_id'] !== undefined && <TopNavItem id='topnavitem_home' icon={faHouse} float='right'/>}
		</div>
		);
}

function TopNavLogo(props){
	return (
		<div className = 'TopNavLogo' id={props.id}>
			<img src={logo} alt='logo' height='30px' width='250px'/>
		</div>
		);
}

function TopNavItem(props){
	
	return (
	<div className='TopNavItem' id={props.id} style={{float:props.float}}>
		<FontAwesomeIcon style={{height: '24px', width: '24px', marginTop:'2px'}} 
			icon={props.icon} size='xl' inverse/>
	</div>
	);
}

export default TopNav;