import './ClientViewSingle.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleForm from '../../Views/ViewEditSingleForm.js';
import { useState, useEffect } from "react";
import {SessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop, faUser, faPlus,faFileCode,faTableList, faPlay, faXmark, faPenToSquare, faPlusSquare, faSearch, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function ClientViewSingle(props){
	
	const navigate = useNavigate();
	
	let {clientId, mode} = useParams();
	
	let disableFields = mode=='view' ? true : false;
	
	var defaultData = {
							'name': '',
							'description': ''
					}
	
	const [clientData, setClientData] = useState(defaultData);
	
	async function getClient(clientId){
		let endpoint = '/clients/' + clientId;
		let response = await SessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json'];
			setClientData(j);
		}
	}
	
	
	useEffect(() => {
			if (mode == 'view' || mode == 'edit'){
				getClient(clientId);
			}
		},[]);
		
	var formData = [{
						'metadata':	{
							id: 'clientData',
							title: null,
							expandable: false,
							_className: 'SearchFormSection',
							fields: [
								{
									id: 'name',
									label: 'Name',
									disabled: disableFields
								},
								{
									id: 'description',
									label: 'Description',
									disabled: disableFields
								}
							]
						},
						'data': clientData,
						'setter':setClientData
					}
				]
	
	async function submit(){
		if(window.confirm('Are you sure you want to save changes to this client?')){
			let endpoint, method;
			if (mode == 'create'){
				endpoint = '/clients';
				method = 'POST';
			}
			if (mode == 'edit'){
				endpoint = '/clients/' + clientId;
				method = 'PATCH';
			}
			try{
				let response = await SessionRequest(endpoint, method, {}, clientData);
				console.log(response);
				navigate('/clients/view');
			}
			catch (err) {alert(err)};
		}
	}
	
	function close(){
		navigate(-1);
	}
	
	let formButtons = mode=='view' ? [{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	] : [
		{
			'id':'submitButton',
			'label': 'Submit',
			'_className': 'TextButton',
			'fn': submit
		},{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	return(
		<div className = 'ClientViewSingle'>
			<Banner icon={faShop} bannerText="Client"/>
			<WorkArea>
				<ViewEditSingleForm title='Client'
					formData = {formData}
					data={clientData}
					dataSetter={setClientData}
					formButtons={formButtons}/>
			</WorkArea>
		</div>
	)
	
	
}

export default ClientViewSingle;