import './ProjectsPage.css'
import Banner from '../Components/Banner.js';
import Table from '../Components/Table.js';
import WorkArea from '../Components/WorkArea.js';
import Flowchart from '../Components/Flowchart.js';
import { useState, useEffect } from "react";
import {SessionRequest} from '../Events/Requester.js'

import {Form, FormSection} from '../Components/Form.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function ProjectsPage(props){
	
	const [data, setData] = useState([]);
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);
	
	function increment(){
		setX(x+5);
		setY(y+5);
	}
	
	return(
		<div className = 'ProjectsPage'>
			<button onClick={increment}>+</button>
			<Flowchart></Flowchart>
		</div>
	)
	
	
}

export default ProjectsPage;