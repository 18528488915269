import './TableForm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';


function TableForm(props){
	
	let metadata = props.TableData['metadata'];
	let selectFunction = props.selectFunction;
	
	return (
		<div className='TableContainer'>
		<table className="Table" id={metadata['id']}>
			<thead>
				<TableHeader Columns={metadata['columns']} />
			</thead>
			<TableBody Data={props.TableData['data']} Columns={metadata['columns']} selectFunction={selectFunction} updater={props.updater} />
		</table>
		</div>
	);
}

function TableHeader(props){
	
	return (
			<tr className="TableHeader">
				{props.Columns.map((column) => <TableHeaderCell key={column['id']} type={column['type']}>{column['label']}</TableHeaderCell>)} 
			</tr>
	);
}

function TableBody(props){
	return (
		<tbody className="TableBody">
			{props.Data.map((row, i) => <TableRow key={'row_' + i} id={i} Columns={props.Columns} Row={row} Index={i} selectFunction={props.selectFunction} updater={props.updater}/>) }
		</tbody>
	);
	
}

function TableRow(props){
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function clearSelected(element){
		let parentTreeview = findParentByClass(element, 'Table')
		let branchHeaders = parentTreeview.getElementsByClassName('TableRow');
		for (let i=0; i<branchHeaders.length; i++){
			if (branchHeaders[i].classList.contains('TableRowSelected')){
				branchHeaders[i].classList.remove('TableRowSelected');
			}
		}
	}
	
	function findIndex(element){
		let row = findParentByClass(element, 'TableRow');
		let table = findParentByClass(element, 'Table');
		let rows = table.getElementsByClassName('TableRow');
		for (let i=0;i<rows.length;i++){
			if (rows[i]==row){
				return i;
			}
		}
	}
	
	function selectRow(e){
		let element = e.target;
		let index = findIndex(element)
		clearSelected(element);
		element = findParentByClass(element, 'TableRow')
		element.classList.toggle('TableRowSelected');
		
		props.selectFunction(index, props.Row);
	}
	
	return (
		<tr className='TableRow' onClick={selectRow}>
			{props.Columns.map((col, i) => 
				<TableCell key={i} Value={props.Row[col['id']]}  Index={props.Index} id={props.id} column={col} updater={props.updater}></TableCell>)}
		</tr>
	);
}

function TableHeaderCell(props){

	return (
		<td className="TableHeaderCell" title={typeof(props.children)==='string'?props.children:null}>
			{props.children}
		</td>
	);
}

function TableCell(props){
	
	return (
		<td className="TableCell" title={typeof(props.children)==='string'?props.children:null}>
			<InputBox defaultValue={props.Value} column={props.column} updater={props.updater}/>
		</td>
	);
}

function InputBox(props){
	
	let column = props.column;
	
	function handleChange(e){
		if (typeof(props.updater) == 'function'){
			let index = findIndex(e.target);
			let val = e.target.value;
			if (column.type=='checkbox'){
				if (e.target.checked){
					val = 'Y';
				}
				else{
					val = 'N';
				}
			}
			props.updater(index, column.id, val)
		}
	}
	
	function findIndex(element){
		let row = findParentByClass(element, 'TableRow');
		let table = findParentByClass(element, 'Table');
		let rows = table.getElementsByClassName('TableRow');
		for (let i=0;i<rows.length;i++){
			if (rows[i]==row){
				return i;
			}
		}
	}
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function defaultButton(e){
		if (e.key == 'Enter' || e.key == 'Return'){
			let element = e.target;
			let formBodyElement = findParentByClass(element, 'FormBody');
			let defaultButtons = formBodyElement.getElementsByClassName("DefaultButton");
			
			if (defaultButtons.length > 0){
				try{
					defaultButtons[0].click();
				} catch (err) {}
			}
		}
	}
	
	return (
		<label htmlFor={column.id} className={'TableFormInputWrapper_' + column.type}>
			{column.type=='file' && <FontAwesomeIcon style={{height: '20px', width: '24px'}} icon={faCloudArrowUp}/>}
			<input defaultValue={props.defaultValue} defaultChecked={props.defaultValue==='Y'?true:false} onKeyDown={defaultButton} onChange={handleChange} className='InputBox' accept={column.accept} placeholder={column.placeholder} type={column.type || 'text'} min={column.min} max={column.max} disabled={column.disabled}/>
		</label>
	);
}

export default TableForm;