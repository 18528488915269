let permissions = [];
let cache = {};
let authDetails;

async function Request(url, method='GET', headers={}, data=null, jsonResponse=true){
	url = '/api/test' + url;
	let requestParams = {
		method: method,
		headers: headers,
	};
	if (data != null){
		requestParams.body = JSON.stringify(data);
	};
	var response = {};
	var resp = await fetch(url, requestParams)
	response['status_code'] = resp.status;
	if (jsonResponse){
		response['json'] = await resp.json();
	}
	return response;
}

async function SessionRequest(url, method='GET', headers={}, data=null, jsonResponse=true){
	let sessionId = getCookie('session_id');
	if (sessionId != null){
		headers['session_id'] = sessionId;
	}
	let response = await Request(url, method, headers, data, jsonResponse);
	return response
}

async function SessionDownload(url, method='GET', headers={}, data=null){
	url = '/api/test' + url;
	let sessionId = getCookie('session_id');
	if (sessionId != null){
		headers['session_id'] = sessionId;
	}
	let requestParams = {
		method: method,
		headers: headers,
	};
	if (data != null){
		requestParams.body = JSON.stringify(data);
	};
	let fname;
	fetch(url, requestParams)
	.then( response => {
		let fnameHeader = response.headers.get('Content-Disposition');
		let fnameHeaderSplit = fnameHeader.split('filename=');
		if (fnameHeaderSplit.length > 1){
			fname = fnameHeaderSplit[1].replace(/^"+/, '').replace(/"+$/, '');
		}
		response.blob().then(blob => {
			var fileUrl = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = fileUrl;
			a.setAttribute('download', fname);
			document.body.appendChild(a);
			a.click();
			a.remove();
	})});
}

async function CachedSessionRequest(url, method='GET', headers={}, data=null, jsonResponse=true, expirySeconds=600){
	let expiry, response;
	let now = new Date();
	try{
		if (cache.hasOwnProperty(url)){
			expiry = cache[url]['expiry'];
			if (expiry > now){
				return cache[url]['response'];
			}
		}
	}
	catch (err){}
	response = await SessionRequest(url, method, headers, data, jsonResponse);
	cache[url] = {};
	expiry = new Date().setSeconds(now.getSeconds()+expirySeconds);
	cache[url]['expiry'] = expiry;
	cache[url]['response'] = response;
	return response;
}

function RefreshCache(){
	cache = {};
}

function getCookie(name){
	let val = null;
	let decodedCookie = decodeURIComponent(document.cookie);
	let cookieArray = decodedCookie.split(';');
	for (let i = 0; i < cookieArray.length; i++){
		let cookie = cookieArray[i];
		let cookieSplit = cookie.split(name+'=');
		if (cookieSplit.length >= 2){
			cookieSplit.shift();
			val = cookieSplit.join(name+'=');
			break;
		}
	}
	return val;
}

async function StartSession(username, password){
	let url = '/get_session_id';
	let method = 'GET';
	let headers = {'Authorization': 'Basic ' + btoa(username + ':' + password)};
	const response = await Request(url, method, headers);
	let session_id = response['json']['session_id'];
	if(session_id !== undefined){
		let expiry = new Date();
		expiry.setTime(expiry.getTime() + (6*60*60*1000));
		document.cookie = 'session_id=' + session_id + ";expires=" + expiry.toUTCString();
		await SetAuthDetails();
		
		return true;
	}
	else{
		return false;
	}
}

async function EndSession(){
	document.cookie = 'session_id=null;expires=Thu, 01 Jan 1970 00:00:01 GMT';
	RefreshCache();
	authDetails = {};
}

function LoggedIn(){
	if (getCookie('session_id') != null && getCookie('session_id').length > 0){
		return true;
	}
	return false;
}

async function SetAuthDetails(){
	authDetails = {}
	if (LoggedIn()){
		let url = "/session";
		let response = await SessionRequest(url);
		let j = await response['json'];
		authDetails = await j;
	}
}

async function GetAuthDetails(){
	if (authDetails === undefined){
		await SetAuthDetails();
	}
	return authDetails;
}

function ValidatePermission(permissionName){
	if (authDetails['permissions'] !== undefined){
		if (authDetails['permissions'].includes(permissionName)){
			return true;
		}
	}
	return false;
}

export {Request, SessionRequest, StartSession, CachedSessionRequest, LoggedIn, EndSession, SessionDownload, RefreshCache, SetAuthDetails, GetAuthDetails, ValidatePermission};