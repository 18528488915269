import './ResourcesPage.css'
import WorkArea from '../Components/WorkArea.js';
import Collapsible from '../Components/Collapsible.js';
import Banner from '../Components/Banner.js';
import Treeview from '../Components/Treeview.js';

import {Form, FormSection} from '../Components/Form.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlus, faPlusSquare, faSearch, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';

function ResourcesPage(props){
	
	var treeViewItems = [
		{'id':'parent1', 'label': 'Parent1', 'children':[
			{
				'id':'child1', 'label': 'Child1', 'children':[
					{'id':'grandchild1', 'label': 'grandchild1', 'children':[]},
					{'id':'grandchild2', 'label': 'grandchild2', 'children':[]},
					{'id':'grandchild3', 'label': 'grandchild3', 'children':[]},
				]
			},
			{
				'id':'child2', 'label': 'Child2', 'children':[
					{'id':'grandchild4', 'label': 'grandchild4', 'children':[]},
					{'id':'grandchild5', 'label': 'grandchild5', 'children':[]},
					{'id':'grandchild6', 'label': 'grandchild6', 'children':[]},
				]
			},
			{
				'id':'child3', 'label': 'Child3', 'children':[
					{'id':'grandchild7', 'label': 'grandchild7', 'children':[]},
					{'id':'grandchild8', 'label': 'grandchild8', 'children':[]},
					{'id':'grandchild9', 'label': 'grandchild9', 'children':[]},
				]
			}
		]},
		{'id':'parent2', 'label': 'Parent2', 'children':[
			{
				'id':'child10', 'label': 'Child10', 'children':[
					{'id':'grandchild11', 'label': 'grandchild11', 'children':[]},
					{'id':'grandchild12', 'label': 'grandchild12', 'children':[]},
					{'id':'grandchild13', 'label': 'grandchild13', 'children':[]},
				]
			},
			{
				'id':'child20', 'label': 'Child20', 'children':[
					{'id':'grandchild24', 'label': 'grandchild24', 'children':[]},
					{'id':'grandchild25', 'label': 'grandchild25', 'children':[]},
					{'id':'grandchild26', 'label': 'grandchild26', 'children':[]},
				]
			},
			{
				'id':'child30', 'label': 'Child30', 'children':[
					{'id':'grandchild37', 'label': 'grandchild37', 'children':[]},
					{'id':'grandchild38', 'label': 'grandchild38', 'children':[]},
					{'id':'grandchild39', 'label': 'grandchild39', 'children':[]},
				]
			}
		]},
	]
	
	//<FormSection id='section' items={formItems} _className = 'FormSection'/>
	return(
		<div className = 'ResourcesPage'>
			<Banner bannerText='Resources'/>
			<WorkArea>
				<Treeview id='testTreeview' items={treeViewItems}/>
			</WorkArea>
		</div>
	)
	
	
}

export default ResourcesPage;