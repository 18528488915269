import HomePage from '../Pages/HomePage.js';
import LandingPage from '../Pages/LandingPage.js';
import InstructionSetViewMany from '../Pages/InstructionSets/InstructionSetViewMany.js';
import InstructionSetViewSingle from '../Pages/InstructionSets/InstructionSetViewSingle.js';
import InstructionSetRunCreate from '../Pages/InstructionSetRuns/InstructionSetRunCreate.js';
import InstructionSetRunViewMany from '../Pages/InstructionSetRuns/InstructionSetRunViewMany.js';
import InstructionSetRunViewSingle from '../Pages/InstructionSetRuns/InstructionSetRunViewSingle.js';
import VariableSetViewMany from '../Pages/VariableSets/VariableSetViewMany.js';
import VariableSetViewSingle from '../Pages/VariableSets/VariableSetViewSingle.js';
import VariableMapViewMany from '../Pages/VariableMaps/VariableMapViewMany.js';
import VariableMapViewSingle from '../Pages/VariableMaps/VariableMapViewSingle.js';
import ClientViewSingle from '../Pages/Clients/ClientViewSingle.js';
import ClientViewMany from '../Pages/Clients/ClientViewMany.js';
import ResourcesPage from '../Pages/ResourcesPage.js';
import ProjectsPage from '../Pages/ProjectsPage.js';
import { Routes, Route, useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

function Refresh(props){
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	let path = searchParams.get("path");
	if (path == '/refresh'){
		path = '/';
	}
	useEffect(() => {navigate(path, {replace: true});},[])
	
	return (
		<div/>
	)
}

function PageSwitch(props){
	const navigate = useNavigate();
	
	function navigateTo(pagePath){
		navigate(pagePath);
	}
	
	window._setCommonFunction('navigate', navigateTo);
	
	return (
		<Routes>
			<Route exact path = '/' element={<LandingPage/>}/>
			<Route exact path = '/refresh' element={<Refresh/>}/>
			<Route path = '/landing' element={<LandingPage/>}/>
			<Route path = '/home' element={<HomePage/>}/>
			<Route path = '/instruction_sets/view' element={<InstructionSetViewMany/>}/>

			<Route path = '/instruction_sets/:instructionSetId/:mode' element={<InstructionSetViewSingle/>}/>
			<Route path = '/instruction_sets/:instructionSetId/runs/create' element={<InstructionSetRunCreate/>}/>
			<Route path = '/instruction_sets/:instructionSetId/runs/view' element={<InstructionSetRunViewMany/>}/>
			<Route path = '/instruction_sets/:instructionSetId/variable_sets/view' element={<VariableSetViewMany/>}/>
			<Route path = '/instruction_sets/:instructionSetId/variable_sets/:varSetId/:mode' element={<VariableSetViewSingle/>}/>
			<Route path = '/instruction_set_runs/:clientId/:instructionSetId/view' element={<InstructionSetRunViewMany/>}/>
			<Route path = '/instruction_set_runs/:runId/view' element={<InstructionSetRunViewSingle/>}/>
			
			<Route path = '/clients/view' element={<ClientViewMany/>}/>
			<Route path = '/clients/:clientId/:mode' element={<ClientViewSingle/>}/>
			
			<Route path = '/variable_maps/view' element={<VariableMapViewMany/>}/>
			<Route path = '/variable_maps/:varMapId/:mode' element={<VariableMapViewSingle/>}/>

			<Route path = '/projects/view' element={<ProjectsPage/>}/>
			<Route path = '/resources/view' element={<ResourcesPage/>}/>
			<Route path = '/resources/:resourceId/:mode' element={<ResourcesPage/>}/>
		</Routes>
	);
}

export default PageSwitch;