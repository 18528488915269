import './PageContainer.css';

function PageContainer(props){
	
	return (
		<div className='PageContainer' id={props.id}>
			{props.children}
		</div>
	);
}

export default PageContainer;