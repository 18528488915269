import './HomePage.css'
import Collapsible from '../Components/Collapsible.js';
import Banner from '../Components/Banner.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';

function HomePage(props){
	
	var formItems = [
	{'id':'name', 'label': 'Name', 'type':null},
	{'id':'desc', 'label': 'Description', 'type':null},
	
	]
	
	return(
		<div className = 'HomePage'>
			
			<Banner bannerText="Home Page"/>
			
		</div>
	)
	
	
}

export default HomePage;