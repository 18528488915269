import './LandingPage.css'
import logo from '../novamoduslogo.png';
import Collapsible from '../Components/Collapsible.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';


function LandingPage(props){
	
	return(
		<div className = 'LandingPage'>			
			<img className='LandingPageLogo' src={logo} width='450px'/>
		</div>
	)
	
	
}

export default LandingPage;