import './WorkArea.css';
function WorkArea(props){
	
	return (
		<div className='WorkArea'>
			{props.children}
		</div>
		);
}

export default WorkArea;