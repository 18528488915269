import './InstructionSetRunViewSingle.css'
import Banner from '../../Components/Banner.js';
import Table from '../../Components/Table.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleFormTable from '../../Views/ViewEditSingleFormTable.js';
import { useState, useEffect } from "react";
import {SessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faRotateRight} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function InstructionSetRunViewSingle(props){
	
	const navigate = useNavigate();
	
	let {runId} = useParams();
	
	var defaultData = {
							'name': '',
							'description': '',
							'start_time': '',
							'end_time': '',
							'status': '',
							'result': ''
							}
	
	const [data, setData] = useState(defaultData);
	const [logData, setLogData] = useState([]);
	
	async function getInstructionSetRun(){
		let endpoint = '/instruction_set_runs/' + runId + '?&expand=logs';
		let response = await SessionRequest(endpoint);
		if (response['status_code'] == 200){
			setData(await response['json']);
		}
		console.log(data);
	}
	
	useEffect(() => {
		getInstructionSetRun();
		},[]);
	
	var tableData = {
						'metadata': {
									'id': 'instructionSetRunLogs',
									'title': 'Instruction Set Run Logs',
									'columns':[
										{
											'id': 'timestamp',
											'label': 'Timestamp'
										},
										{
											'id': 'result',
											'label': 'Result'
										},
										
										{
											'id': 'message',
											'label': 'Message'
										}
									]
								},
						'data': logData
	}
	
	
	var formData = [
						{
							'metadata':	{
									id: 'runData',
									title: null,
									expandable: false,
									_className: 'SearchFormSection',
									fields: [
										{
											id: 'name',
											label: 'Name',
											disabled: true
										},
										{
											id: 'description',
											label: 'Description',
											disabled: true
										},
										{
											id: 'start_time',
											label: 'Start Time',
											disabled: true
										},
										{
											id: 'end_time',
											label: 'End Time',
											disabled: true
										},
										{
											id: 'status',
											label: 'Status',
											disabled: true
										},
										{
											id: 'result',
											label: 'Result',
											disabled: true
										}
									]
								},
						'data': data,
						'setter': setData
					}
				]
	
	function refresh(){
		getInstructionSetRun(runId);
	}
	
	function close(){
		console.log("navigate");
		navigate(-1);
	}
	
	let formButtons = [
		{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	function updater(){
		
	}
	
	let toolbarButtons = [
							{
								'id':'refresh', 
								'label': 'Reload',
								'_className': 'ToolbarButton', 
								'icon':faRotateRight, 
								fn: getInstructionSetRun
							}
						];
	
	function selectFunction(){
		
	}
	
	return(
		<div className = 'InstructionSetRunViewSingle'>
			<Banner icon={faVial} bannerText="Test Run"/>
			<WorkArea>
				<ViewEditSingleFormTable title='Variable Map'
					formData = {formData}
					TableData={tableData}
					dataSetter={setData}
					formButtons={formButtons} 
					toolbarButtons={toolbarButtons}
					selectFunction={selectFunction}
					updater={updater}/>
			</WorkArea>
		</div>
	)
	
	
}

export default InstructionSetRunViewSingle;