import './VariableSetViewSingle.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleForm from '../../Views/ViewEditSingleForm.js';
import { useState, useEffect } from "react";
import {SessionRequest,CachedSessionRequest} from '../../Events/Requester.js'
import { useParams } from 'react-router-dom';

function VariableSetViewSingle(props){
	
	let {instructionSetId, varSetId, mode} = useParams();
	
	let disableFields = mode==='view' ? true : false;
	
	var defaultData = {
							'name': '',
							'description': '',
							'client_id': 0
					}
	
	const [varSetData, setVarSetData] = useState(defaultData);
	const [variableFieldData, setVariableFieldData] = useState([]);
	const [variableData, setVariableData] = useState({});
	const [refStartDate, setRefStartDate] = useState('2000-01-01');
	const [clientOptions, setClientOptions] = useState([]);
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] === 200){
			let j = await response['json']
			let updatedClientOptions = [];
			updatedClientOptions.push({'value': 0, 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let client = {};
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			await setClientOptions(updatedClientOptions);
		}
	}
	
	async function getVariableSet(instructionSetId, varSetId){
		let endpoint = '/instruction_sets/' + instructionSetId + '/variable_sets/' + varSetId + '?&expand=variables';
		let response = await SessionRequest(endpoint);
		if (response['status_code'] === 200){
			let j = await response['json'];
			setVarSetData(j);
			let varData = {};
			for (let i=0;i < j['variables'].length;i++){
				let variable = j['variables'][i];
				let key = variable['instruction_param_id'] + '~' + variable['instruction_param_seq'];
				varData[key] = variable['value'];
			}
			setVariableData(varData);
		}
	}
	
	function getFields(variableData){
		var fields = [];
		for (let i=0; i<variableData.length; i++){
			let newField = {};
			newField['id'] = variableData[i]['param_id'] + '~' + variableData[i]['param_seq']
			newField['label'] = variableData[i]['instruction_name'] + ': ' + variableData[i]['var_name']
			newField['disabled'] = disableFields
			fields.push(newField);
		}
		return fields;
	}
	
	async function getInstructionSetVariables(instructionSetId){
		let endpoint = '/instruction_sets/' + instructionSetId + '/variables?as_list=true';
		let response = await SessionRequest(endpoint);
		if (response['status_code'] === 200){
			let instructionSetVariables = await response['json'];
			setVariableFieldData(getFields(instructionSetVariables));
		}
	}
	
	async function getRefStartDate(instructionSetId){
		let endpoint = '/instruction_sets/' + instructionSetId + "?q=active_flag='Y'&fields=start_date";
		let response = await SessionRequest(endpoint);
		if (response['status_code'] === 200){
			let j = await response['json'];
			setRefStartDate(j['start_date']);
		}
	}
	
	var formData = [{
						'metadata':	{
							id: 'varSetData',
							title: null,
							expandable: false,
							_className: 'SearchFormSection',
							fields: [
								{
									id: 'name',
									label: 'Name',
									disabled: disableFields
								},
								{
									id: 'description',
									label: 'Description',
									disabled: disableFields
								},
								{
									id: 'client_id',
									label: 'Client',
									disabled: disableFields,
									type: 'select',
									selectOptions: clientOptions
								}
							]
						},
						'data': varSetData,
						'setter':setVarSetData
					},
					{
						'metadata':	{
							id: 'variableData',
							title: 'Variables',
							expandable: false,
							_className: 'SearchFormSection',
							fields: variableFieldData || []
						},
						'data': variableData,
						'setter':setVariableData
					}
				]
	
	async function submit(){
		if(window.confirm('Are you sure you want to save changes to this variable set?')){
			let endpoint, method;
			if (mode === 'create'){
				endpoint = '/instruction_sets/' + instructionSetId + '/variable_sets';
				method = 'POST';
			}
			if (mode === 'edit'){
				endpoint = '/instruction_sets/' + instructionSetId + '/variable_sets/' + varSetId;
				method = 'PATCH';
			}
			try{
				let varSetObject = {};
				varSetObject['instruction_set_id'] = instructionSetId;
				varSetObject['ref_start_date'] = refStartDate;
				for (let field in varSetData){
					if (field === 'name' && varSetData[field].length === 0){
						throw new Error('Name is mandatory.');
					}
					if (field === 'variables') { continue; }
					varSetObject[field] = varSetData[field];
				}
				varSetObject['variables'] = [];
				for (let i=0;i<variableFieldData.length;i++){
					let newVariable = {};
					let id_spl = variableFieldData[i]['id'].split('~');
					newVariable['instruction_param_id'] = id_spl[0];
					newVariable['instruction_param_seq'] = id_spl[1];
					newVariable['value'] = variableData[variableFieldData[i]['id']];
					varSetObject['variables'].push(newVariable);
				}
				await SessionRequest(endpoint, method, {}, varSetObject);
				window._getCommonFunction('navigate')('/instruction_sets/' + instructionSetId + '/variable_sets/view');
			}
			catch (err) {alert(err)};
		}
	}
	
	function close(){
		window._getCommonFunction("navigate")(-1);
	}
	
	let formButtons = mode==='view' ? [{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	] : [
		{
			'id':'submitButton',
			'label': 'Submit',
			'_className': 'TextButton',
			'fn': submit
		},{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	useEffect(() => {
			getClients();
			getInstructionSetVariables(instructionSetId, varSetId);
			if (mode === 'view' || mode === 'edit'){
				getVariableSet(instructionSetId, varSetId);
			}
			getRefStartDate(instructionSetId);
		},[]);
		
	
	return(
		<div className = 'VariableSetViewSingle'>
			<Banner bannerText="Variable Set"/>
			<WorkArea>
				<ViewEditSingleForm title='Variable Sets'
					formData = {formData}
					data={variableFieldData}
					dataSetter={setVariableFieldData}
					formButtons={formButtons}/>
			</WorkArea>
		</div>
	)
	
	
}

export default VariableSetViewSingle;