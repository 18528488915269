import './VariableMapViewMany.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faEye, faPlus, faXmark, faPenToSquare, faAngleRight, faAngleLeft, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function VariableMapViewMany(props){
	
	const navigate = useNavigate();
	
	var defaultSearchData = {
							'name': '',
							'description': '',
							'client': ''
							}
	
	const [searchData, setSearchData] = useState(defaultSearchData);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [addDisabled, setAddDisabled] = useState(false);
	const [tablebackDisabled, setTablebackDisabled] = useState(true);
	const [tableforwardDisabled, setTableforwardDisabled] = useState(true);
	const [viewDisabled, setViewDisabled] = useState(true);
	const [editDisabled, setEditDisabled] = useState(true);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [clientOptions, setClientOptions] = useState([]);
	const [page, setPage] = useState(0);
	
	
	var searchFormData = [
		{
			'metadata': {
						id: 'searchFormData',
						title: null,
						expandable: true,
						_className: 'SearchFormSection',
						fields: [
							{
								id: 'name',
								label: 'Name',
								placeholder: 'Name',
							},
							{
								id: 'description',
								label: 'Description',
								placeholder: 'Description'
							},
							{
								id: 'client_id',
								label: 'Client',
								type: 'select',
								selectOptions: clientOptions
							}
						]
					},
			'data': searchData,
			'setter': setSearchData
		}
	];
	
	var tableData = {
						'metadata': {
										'id': 'instructionSetsTable',
										'title': 'Instruction Sets',
										'columns':[
											{
												'id': 'name',
												'label': 'Name'
											},
											{
												'id': 'description',
												'label': 'Description'
											},
											{
												'id': 'client_id',
												'label': 'Client'
											}
										]
									},
						'data': data
	}
	
	async function searchFunction(page=0){
		let offset = page==0 ? 0 : (page*25)-1;
		let endpoint = '/variable_maps?limit=25&offset='+offset;
		let qParam = window._getCommonFunction('buildSearchQuery')(searchData);
		endpoint += "&q=active_flag='Y'"+qParam;
		let response = await SessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			setData(response['json']['items']);
			if (response['json']['has_more'] == true){
				setTableforwardDisabled(false);
			}
			else{
				setTableforwardDisabled(true);
			}
			if (page>0){
				setTablebackDisabled(false);
			}
			else{
				setTablebackDisabled(true);
			}
		}
	}
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await SessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': null, 'displayValue': null});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			setClientOptions(updatedClientOptions);
		}
	}
	
	function selectFunction(data){
		setSelected(data);
		setViewDisabled(false);
		setEditDisabled(false);
		setDeleteDisabled(false);
	}
	
	function deselectFunction(data){
		setViewDisabled(true);
		setEditDisabled(true);
		setDeleteDisabled(true);
	}
	
	async function tableBack(){
		if (page > 0){
			let lastPage = page-1;
			setPage(lastPage);
			searchFunction(lastPage);
		}
	}
	
	async function tableForward(){
		let nextPage = page+1;
		setPage(nextPage);
		searchFunction(nextPage);
	}
	
	function addVariableMap(){
		navigate('/variable_maps/0/create');
	}
	
	function viewVariableMap(){
		navigate('/variable_maps/' + selected['id'] + '/view');
	}
	
	function editVariableMap(){
		navigate('/variable_maps/' + selected['id'] + '/edit');
	}
	
	async function deleteVariableMap(){
		if(window.confirm("Are you sure you want to delete?")){
			let endpoint = '/instruction_sets/' + selected['id'];
			let response = await SessionRequest(endpoint, 'DELETE');
			if (response['status_code'] == 204){
				alert('Successfully deleted instruction set.');
			}
			else{
				alert('An error occured while deleting instruction set.');
			}
		}
	}
	
	
	var toolbarButtons = [
		{
			'id':'add', 
			'label': 'Create Variable Map',
			'_className': 'ToolbarButton', 
			'icon':faPlus, 
			fn: addVariableMap,
			disabled: addDisabled
		},
		{
			'id':'refresh', 
			'label': 'Refresh',
			'_className': 'ToolbarButton', 
			'icon':faRotate,
			fn: searchFunction,
		},
		{
			'id':'tableBack', 
			'label': 'Back',
			'_className': 'ToolbarButton', 
			'icon':faAngleLeft,
			fn: tableBack,
			disabled: tablebackDisabled
		},
		{
			'id':'tableForward', 
			'label': 'Forward',
			'_className': 'ToolbarButton', 
			'icon':faAngleRight,
			fn: tableForward,
			disabled: tableforwardDisabled
		},
		{
			'id':'view', 
			'label': 'View Variable Map',
			'_className': 'ToolbarButton', 
			'icon':faEye, 
			fn: viewVariableMap,
			disabled: viewDisabled
		},
		{
			'id':'edit', 
			'label': 'Edit Variable Map',
			'_className': 'ToolbarButton', 
			'icon':faPenToSquare, 
			fn: editVariableMap,
			disabled: editDisabled
		},
		{
			'id':'delete', 
			'label': 'Delete Variable Map',
			'_className': 'ToolbarButton', 
			'icon':faXmark, 
			fn: deleteVariableMap,
			disabled: deleteDisabled
		}
	]
	
	useEffect(() => {
		getClients();
		},[]);
	
	return(
		<div className = 'VariableMapViewMany'>
			<div>
			<Banner icon={faMap} bannerText="Variable Maps"/>
			<WorkArea>
				<ViewManySearchTable defaultSearchData={defaultSearchData} 
					searchFormData={searchFormData} 
					searchFunction={searchFunction} 
					toolbarButtons={toolbarButtons} 
					tableData={tableData}
					selectFunction={selectFunction}
					deselectFunction={deselectFunction}/>
			</WorkArea>
			</div>
		</div>
	)
	
	
}

export default VariableMapViewMany;