import './Banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Banner(props){
	
	const id = props.id;
	
	
	return (
		<div id={props.id} className='Banner'>
			<span className='BannerIcon'>{props.icon != null && <FontAwesomeIcon style={{height: '40px', width: '40px'}} 
			icon={props.icon} size='xl' inverse/>}</span>
			{props.bannerText}
		</div>
	);
	
}
export default Banner;