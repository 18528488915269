import './Treeview.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import {useState} from 'react';


function Treeview(props){
	
	/*
		items: [
		{'id':'', 'label':, 'children':[
			{'id':'', 'label':'','children':[]},
			]
		]
	*/
	
	let items = props.items;
	
	
	return (
		<div className="Treeview">
			{items.map((item) => <TreeviewBranch key={item['id']} id={item['id']} label={item['label']} items={item['children']}/>)} 
		</div>
	);
}

function TreeviewBranch(props){
	
	let id = props.id;
	let label = props.label;
	let items = props.items;
	let showIcon = items.length > 0 ? true : false;
	const [expanded, setExpanded] = useState(false);
	
	const expandFn = () => {
		setExpanded(!expanded);
		document.getElementById(id+'_children').classList.toggle('TreeviewChildrenExpanded');
	}
	
	return (
			<div className="TreeviewBranch">
				<TreeviewBranchHeader expandFn={expandFn} showIcon={showIcon} expanded={expanded}>{props.label}</TreeviewBranchHeader>
				<TreeviewChildren id={id+'_children'}>
					{items.map((item) => <TreeviewBranch key={item['id']} id={item['id']} label={item['label']} items={item['children']}/>)} 
				</TreeviewChildren>
			</div>
	);
}


function TreeviewBranchHeader(props){
	
	let showIcon = props.showIcon;
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function clearSelected(element){
		let parentTreeview = findParentByClass(element, 'Treeview')
		let branchHeaders = parentTreeview.getElementsByClassName('TreeviewBranchHeader');
		for (let i=0; i<branchHeaders.length; i++){
			if (branchHeaders[i].classList.contains('TreeviewBranchSelected')){
				branchHeaders[i].classList.remove('TreeviewBranchSelected');
			}
		}
	}
	
	function selectBranch(e){
		let element = e.target;
		clearSelected(element);
		element = findParentByClass(element, 'TreeviewBranchHeader')
		element.classList.toggle('TreeviewBranchSelected');
	}
	
	return (
			<div className="TreeviewBranchHeader" onClick={selectBranch}>
				<TreeviewBranchLabel>{props.children}</TreeviewBranchLabel>
				{showIcon && <TreeviewExpandIcon expandFn={props.expandFn} expanded={props.expanded}/>}
			</div>
	);
}

function TreeviewBranchLabel(props){
	return (
			<div className="TreeviewBranchLabel">
				{props.children}
			</div>
	);
}

function TreeviewExpandIcon(props){
	
	return (
			<div className="TreeviewExpandIcon" onClick={props.expandFn}>
				{props.expanded ? <FontAwesomeIcon icon={faCaretUp} style={{height:'20px'}}/> : <FontAwesomeIcon icon={faCaretDown} style={{height:'20px'}}/>}
			</div>
	);
}

function TreeviewChildren(props){
	
	let items = props.items;
	
	return (
			<div id={props.id} className="TreeviewChildren">
				{props.children}
			</div>
	);
}

export default Treeview;