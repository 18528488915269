import './Form.css';
import {TextButton} from './Button.js';
import Toolbar from './Toolbar.js';
import Table from './Table.js';
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp} from '@fortawesome/free-solid-svg-icons';
import { faSquarePlus, faSquareMinus} from '@fortawesome/free-regular-svg-icons';

function Form(props){
	
	// data, metadata
	// metadata maps each label and info about that field such as type to an id
	// the id is the field name in data
	
	// metadata = {'id':'', 'title':'','fields': [{'id':'', 'label':'', 'type':'', 'placeholder':''}]
	
	const id = props.id;
	const title = props.title;
	const sections = props.sections || [];
	const buttons = props.buttons || [];
	let expandable = props.expandable;
	const [icon, setIcon] = useState(faSquareMinus);
	
	function expand(){
		let element = document.getElementById(id);
		element.classList.toggle('FormCollapsed');
		changeIcon();
	}
	
	function changeIcon(){
		if (icon === faSquareMinus){
			setIcon(faSquarePlus);
		}
		else {
			setIcon(faSquareMinus);
		}
	}		
	
	return (
		<div className={props._className}>
			{(typeof(title)=='string' && title.length > 0) && <div id= {props.id + "_title"} className='FormTitle' onClick={expandable ? expand : null}>{title}{expandable==true &&<span><FontAwesomeIcon icon={icon} size='xs'/></span>}</div>}
			<div id={props.id} className='FormBody'>
				{sections.map((section) => <FormSection key={section['metadata']['id']} id={id + '_' + section['metadata']['id']} title={section['metadata']['title']} fields={section['metadata']['fields']} data={section['data']} setter={section['setter']} expandable={section['metadata']['expandable']} _className={section['metadata']['_className']}/>)}
				{props.children}
				<div className='FormButtons'>
					{buttons.map((button) => <TextButton key={button['id']} id={id + '_' + button['id']} label={button['label']} _className={button['_className']} fn={button['fn']}/>)}
				</div>
			</div>
		</div>
	);
}

function FormSection(props){
	
	const id = props.id;
	const title = props.title;
	const fields = props.fields;
	let setData = props.setter;
	let expandable = props.expandable;
	const [icon, setIcon] = useState(faSquareMinus);
		
	function expand(){
		let element = document.getElementById(id);
		element.classList.toggle('FormSectionCollapsed');
		if (icon === faSquareMinus){
			setIcon(faSquarePlus);
		}
		else {
			setIcon(faSquareMinus);
		}
	}
	
	function updateField(fieldId, val){
		let updatedData = props.data;
		updatedData[fieldId] = val;
		setData(updatedData);
	}
	
	
	return (
		<div>
		{(typeof(title)=='string' && title.length > 0) && <div className='FormTitle' onClick={expandable ? expand : null}>{title}{expandable==true &&<span><FontAwesomeIcon icon={icon} size='xs'/></span>}</div>}
			<div id={props.id}>
				<div className={props._className}>
					{fields.map((field) => <InputField key={field['id']} id={id + '_' + field['id']} field={field} data={field['type']=='table' ? field['tableData'] : props.data[field['id']] } updater={updateField}/>)}
				</div>
			</div>
		</div>
	);
}

function InputField(props){
	
	const id = props.id;
	const labelId = id + '_label';
	const inputId = id + '_input';
	const updater = props.updater;
	const selectOptions = props.field['selectOptions']
	
	let _className = props.field['type']=='table' ? null : 'InputField';
	
	return (
		<div id={props.id} className={_className}>
			{props.field['type']!=='table' &&<Label id={labelId} htmlFor={inputId} label={props.field['label']}/>}
			{props.field['type']=='select'
				? <SelectBox id={inputId} 
							fieldId={props.field['id']}
							_value={props.data} 
							updater={props.updater} 
							options={props.field['selectOptions']}
							disabled={props.field['disabled']}/>
				: props.field['type']=='multisearchbox'
				? <MultiSearchBox id={props.field['id']} 
							data={props.data} 
							updater={props.updater}
							selectFunction={props.field['itemSelectFunction']}
							toolbarButtons={props.field['toolbarButtons']}
							disabled={props.field['disabled']}/>
				: props.field['type']=='textarea'
				? <TextAreaBox id={props.field['id']} 
							_value={props.data} 
							updater={props.updater}
							rows={props.field['rows']}
							cols={props.field['cols']}
							disabled={props.field['disabled']}/>
				: props.field['type']=='table'
				? <Table TableData={props.data}/>
				: <InputBox id={inputId} 
							fieldId={props.field['id']} 
							_value={props.data} 
							updater={props.updater} 
							placeholder={props.field['placeholder']} 
							type={props.field['type']} 
							disabled={props.field['disabled']}/>}
		</div>
	);
}

function Label(props){
	
	return (
		<label id={props.id} className='FormLabel' htmlFor={props.inputId}>{props.label}</label>
	);
	
}

function SelectBox(props){
	
	let options = props.options || []
		
	function setDefault(){
		let e = document.getElementById(props.id);
		e.value = props._value;
		
	}
	
	function _handleChange(e){
		let element = e.target;
		let val = element.children[element.selectedIndex].value;
		if (typeof(props.updater) == 'function'){
			props.updater(props.fieldId, val)
		}
	}
	
	function handleChange(e){
		let element = e.target;
		let val = element.children[element.selectedIndex].value;
		if (typeof(props.updater) == 'function'){
			props.updater(props.fieldId, val)
			element.value=val;
		}
	}
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function defaultButton(e){
		if (e.key == 'Enter' || e.key == 'Return'){
			let element = e.target;
			let formBodyElement = findParentByClass(element, 'FormBody');
			let defaultButtons = formBodyElement.getElementsByClassName("DefaultButton");
			
			if (defaultButtons.length > 0){
				try{
					defaultButtons[0].click();
				} catch (err) {}
			}
		}
	}
		
	useEffect(() => {
			setDefault();
		},[props.options, props._value]);
	
	return (
		<label htmlFor={props.id} className='InputWrapper_select'>
			<select id = {props.id} onKeyDown={defaultButton} onChange={(item) => {handleChange(item)}} className='InputBox' accept={props.accept} disabled={props.disabled}>
				{options.map((_option) => <option key={_option['value']} value={_option['value']} >{_option['displayValue']}</option>)}
			</select>
		</label>
	);
}

function InputBox(props){
	
	function handleChange(e){
		if (typeof(props.updater) == 'function'){
			props.updater(props.fieldId, e.target.value)
		}
	}
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function defaultButton(e){
		if (e.key == 'Enter' || e.key == 'Return'){
			let element = e.target;
			let formBodyElement = findParentByClass(element, 'FormBody');
			let defaultButtons = formBodyElement.getElementsByClassName("DefaultButton");
			
			if (defaultButtons.length > 0){
				try{
					defaultButtons[0].click();
				} catch (err) {}
			}
		}
	}
	
	return (
		<label htmlFor={props.id} className={'InputWrapper_' + props.type}>
			{props.type=='file' && <FontAwesomeIcon style={{height: '20px', width: '24px'}} icon={faCloudArrowUp}/>}
			<input id = {props.id} defaultValue={props._value} defaultChecked={props._value==='Y'?true:false} onKeyDown={defaultButton} onChange={handleChange} className='InputBox' accept={props.accept} placeholder={props.placeholder} type={props.type} min={props.min} max={props.max} disabled={props.disabled}/>
		</label>
	);
}

function MultiSearchBox(props){
	
	let data = props.data || [];
	
	function clearSelected(element){
		let parentMultiSearchBox = findParentByClass(element, 'MultiSearchBox')
		let branchHeaders = parentMultiSearchBox.getElementsByClassName('MultiSearchBoxItem');
		for (let i=0; i<branchHeaders.length; i++){
			if (branchHeaders[i].classList.contains('MultiSearchBoxItemSelected')){
				branchHeaders[i].classList.remove('MultiSearchBoxItemSelected');
			}
		}
	}
	
	function findIndex(element){
		let row = findParentByClass(element, 'MultiSearchBoxItem');
		let table = findParentByClass(element, 'MultiSearchBox');
		let rows = table.getElementsByClassName('MultiSearchBoxItem');
		for (let i=0;i<rows.length;i++){
			if (rows[i]==row){
				return i;
			}
		}
	}
	
	function selectItem(e){
		let element = e.target;
		let index = findIndex(element);
		clearSelected(element);
		element = findParentByClass(element, 'MultiSearchBoxItem')
		element.classList.toggle('MultiSearchBoxItemSelected');
		props.selectFunction(index, data);
	}
	
	function handleChange(e){
		if (typeof(props.updater) == 'function'){
			props.updater(props.fieldId, e.target.value)
		}
	}
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	return (
		<div className='MultiSearchBoxContainer'>
			<Toolbar _className='MultiSearchBoxToolbar' buttons={props.toolbarButtons}/>
			<div className={'MultiSearchBox'}>
				{data.map((item, idx) => <MultiSearchBoxItem key={item['id']} itemId={item['id']} label={item['label']} selectFunction={selectItem}/>)}
			</div>
		</div>
	);
}

function MultiSearchBoxItem(props){
	
	return (
		<div id={props.id} className='MultiSearchBoxItem' onClick={props.selectFunction}>
			{props.label}
		</div>
	);
}

function TextAreaBox(props){
	
	function handleChange(e){
		if (typeof(props.updater) == 'function'){
			props.updater(props.fieldId, e.target.value)
		}
	}
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function defaultButton(e){
		if (e.key == 'Enter' || e.key == 'Return'){
			let element = e.target;
			let formBodyElement = findParentByClass(element, 'FormBody');
			let defaultButtons = formBodyElement.getElementsByClassName("DefaultButton");
			
			if (defaultButtons.length > 0){
				try{
					defaultButtons[0].click();
				} catch (err) {}
			}
		}
	}
	
	return (
		<label htmlFor={props.id} className={'InputWrapper_' + props.type}>
			{props.type=='file' && <FontAwesomeIcon style={{height: '20px', width: '24px'}} icon={faCloudArrowUp}/>}
			<textarea id = {props.id} defaultValue={props._value} onKeyDown={defaultButton} onChange={handleChange} className='InputBox' rows={props.rows} cols={props.cols} disabled={props.disabled}/>
		</label>
	);
}

export {Form, FormSection};