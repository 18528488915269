import './ViewManySearchTableModal.css'
import Table from '../Components/Table.js';
import Modal from '../Components/Modal.js';
import Toolbar from '../Components/Toolbar.js';
import { useState, useEffect } from "react";
import { Form, FormSection } from '../Components/Form.js';

function ViewManySearchTableModal(props){
	
	let data = props.data;
	var searchFormData = props.searchFormData;
	
	async function searchFunction(e){
		await props.searchFunction(e);
		document.getElementById('modalSearchForm').classList.add('FormCollapsed');
	}
	
	var formButtons = [
		{
			'id':'searchButton', 
			'label':'Search', 
			'_className': 'TextButton DefaultButton', 
			'fn': searchFunction
		}
	]
	
	let toolbarButtons = props.toolbarButtons;
	
	return(
		<Modal id={props.id}>
			<div className = 'ViewManySearchTableModal'>
				<Form id = 'modalSearchForm' 
					title='Search' 
					expandable={true} 
					sections = {props.searchFormData} 
					buttons= {formButtons} 
					_className = 'SearchForm'/>
				<Toolbar buttons={toolbarButtons} />
				<Table id='table' TableData={props.tableData} 
					selectFunction={props.selectFunction}/>
			</div>
		</Modal>
	)
	
	
}

export default ViewManySearchTableModal;