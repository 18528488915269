import './InstructionSetRunCreate.css'
import Banner from '../../Components/Banner.js';
import Modal from '../../Components/Modal.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewEditSingleForm from '../../Views/ViewEditSingleForm.js';
import ViewManySearchTableModal from '../../Views/ViewManySearchTableModal.js';
import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest, GetPermissions} from '../../Events/Requester.js'
import {Form, FormSection} from '../../Components/Form.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faMagnifyingGlass,faSquarePlus, faArrowUp, faArrowDown, faUser, faPlus,faFileCode, faPlay, faXmark, faPenToSquare, faPlusSquare, faSearch, faBars, faGear, faCircleQuestion, faRobot, faShield, faHouse, faBell, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function InstructionSetRunCreate(props){
	
	const navigate = useNavigate();
	
	let {instructionSetId} = useParams();
	
	var defaultRunData = {
							'name': '',
							'description': '',
							'client_id': null,
							'document_flag': '',
							'var_set_id': null
						}
	var defaultOptionData = {
							'implicit_wait': 5,
							'explicit_wait': 0.5,
							'browser': 'chrome',
							'document_flag': 'Y',
						}

	const [clientOptions, setClientOptions] = useState([]);
	const [varSetOptions, setVarSetOptions] = useState([]);
	
	const [runData, setRunData] = useState(defaultRunData);
	const [optionData, setOptionData] = useState(defaultOptionData);
	const [varMapData, setVarMapData] = useState({'var_maps': []});
	const [runtimeVarFields, setRuntimeVarFields] = useState([]);
	const [runtimeVarData, setRuntimeVarData] = useState([]);
	const [selectedVarMapIndex, setSelectedVarMapIndex] = useState(-1);
	const [modalSearchData, setModalSearchData] = useState({});
	const [modalData, setModalData] = useState([]);
	const [selectedModalData, setSelectedModalData] = useState(null);
	const [moveMapUpDisabled, setMoveMapUpDisabled] = useState(true);
	const [moveMapDownDisabled, setMoveMapDownDisabled] = useState(true);
	const [deleteMapDisabled, setDeleteMapDisabled] = useState(true);
	const [modalAddMapDisabled, setModalAddMapDisabled] = useState(true);
	
	function varMapItemSelectFunction(index, itemData){
		setSelectedVarMapIndex(index);
		setMoveMapUpDisabled(false);
		setMoveMapDownDisabled(false);
		setDeleteMapDisabled(false);
	}

	function addVarMap(){
		if (selectedModalData != null){
			let id = selectedModalData['id'];
			let label = selectedModalData['name'];
			for (let i=0;i<varMapData['var_maps'].length;i++){
				let currentVarMap = varMapData['var_maps'][i];
				if (currentVarMap['id'] == id){
					return;
				}
			}
			let updatedVarMapData = new Object();
			updatedVarMapData['var_maps'] = varMapData['var_maps'].slice();
			updatedVarMapData['var_maps'].push({'id':id, 'label': label, 'data': selectedModalData});
			let mapItems = selectedModalData['map_items'];
			let updatedRuntimeFields = runtimeVarFields.slice();
			let mapItem, newField;
			for (let i=0;i < (mapItems || []).length;i++){
				mapItem = mapItems[i];
				if (mapItem['runtime_var_flag'] == 'Y'){
					newField = new Object();
					newField['id'] = id + '~' + mapItem['key'];
					newField['label'] = mapItem['key'];
					if (mapItem['sensitive_flag'] == 'Y'){
						newField['type'] = 'password';
					}
					updatedRuntimeFields.push(newField);
				}
			}
			setRuntimeVarFields(updatedRuntimeFields);
			setVarMapData(updatedVarMapData);
		}
	}
	
	function moveVarMapUp(){
		if (selectedVarMapIndex > 0){
			let updatedVarMapData = new Object();
			updatedVarMapData['var_maps'] = varMapData['var_maps'].slice();
			let selectedVarMap = updatedVarMapData['var_maps'].splice(selectedVarMapIndex,1)[0];
			updatedVarMapData['var_maps'].splice(selectedVarMapIndex-1,0,selectedVarMap);
			setVarMapData(updatedVarMapData);
			setSelectedVarMapIndex(selectedVarMapIndex-1);
		}
	}
	
	function moveVarMapDown(){
		if (selectedVarMapIndex < varMapData['var_maps'].length-1 && selectedVarMapIndex >= 0){
			let updatedVarMapData = new Object();
			updatedVarMapData['var_maps'] = varMapData['var_maps'].slice();
			let selectedVarMap = updatedVarMapData['var_maps'].splice(selectedVarMapIndex,1)[0];
			updatedVarMapData['var_maps'].splice(selectedVarMapIndex+1,0,selectedVarMap);
			setVarMapData(updatedVarMapData);
			setSelectedVarMapIndex(selectedVarMapIndex+1);
		}
	}
	
	function removeVarMap(){
		if (varMapData['var_maps'].length>0 && selectedVarMapIndex >= 0){
			let updatedVarMapData = new Object();
			updatedVarMapData['var_maps'] = varMapData['var_maps'].slice();
			let selectedVarMap = updatedVarMapData['var_maps'].splice(selectedVarMapIndex,1)[0];
			let updatedRuntimeFields = new Array();
			let mapItem, newField, runFieldId;
			let mapItems = selectedVarMap['data']['map_items'];
			let mapId = selectedVarMap['id'];
			let removeIndices = [];
			updatedRuntimeFields = runtimeVarFields.slice();
			for (let i=0;i < updatedRuntimeFields.length;i++){
				runFieldId = updatedRuntimeFields[i]['id'];
				if (runFieldId.split('~')[0] == mapId){
					removeIndices.push(i);
				}
			}
			removeIndices.reverse();
			for (let i=0;i<removeIndices.length;i++){
				updatedRuntimeFields.splice(removeIndices[i],1);
			}
			setVarMapData(updatedVarMapData);
			setRuntimeVarFields(updatedRuntimeFields);
			setSelectedVarMapIndex(-1);
		}
	}
	
	function searchVarMap(){
		document.getElementById('varMapSearchModal').classList.toggle('ModalHidden');
	}
	
	var toolbarButtons = [
		{
			'id':'add', 
			'label': 'Add Map',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faMagnifyingGlass, 
			'fn': searchVarMap,
		},
		{
			'id':'moveUp', 
			'label': 'Move Map Up',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faArrowUp, 
			'fn': moveVarMapUp,
			'disabled': moveMapUpDisabled
		},
		{
			'id':'moveDown', 
			'label': 'Move Map Down',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faArrowDown, 
			'fn': moveVarMapDown,
			'disabled': moveMapDownDisabled
		},
		{
			'id':'delete', 
			'label': 'Remove Map',
			'_className': 'MultiSearchToolbarButton', 
			'icon':faXmark, 
			'fn': removeVarMap,
			'disabled': deleteMapDisabled
		}
	]
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': null, 'displayValue': null});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			await setClientOptions(updatedClientOptions);
		}
	}
	
	async function getVarSets(){
		let endpoint = '/instruction_sets/'+instructionSetId+'/variable_sets?fields=id,name';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedVarSetOptions = new Array();
			updatedVarSetOptions.push({'value': null, 'displayValue': null});
			for (let i=0; i<j['items'].length;i++){
				let varSet = new Object();
				varSet['value'] = j['items'][i]['id'];
				varSet['displayValue'] = j['items'][i]['name'];
				updatedVarSetOptions.push(varSet);
			}
			await setVarSetOptions(updatedVarSetOptions);
		}
	}
	
	var formData = [
							{
								'metadata': {
									id: 'formData',
									title: null,
									expandable: false,
									_className: 'SearchFormSection',
									fields: [
										{
											id: 'name',
											label: 'Name'
										},
										{
											id: 'description',
											label: 'Description'
										},
										{
											id: 'client_id',
											label: 'Client',
											type: 'select',
											selectOptions: clientOptions
										},
										{
											id: 'var_set_id',
											label: 'Variable Set',
											type: 'select',
											selectOptions: varSetOptions
										}
									]
								},
							'data': runData,
							'setter': setRunData
							},
							{
								'metadata': {
									id: 'optionsFormSection',
									title: 'Options',
									expandable: true,
									_className: 'SearchFormSection',
									fields: [
										{
											id: 'implicit_wait',
											label: 'Implicit Wait',
											type: 'number'
										},
										{
											id: 'explicit_wait',
											label: 'Explicit Wait',
											type: 'number'
										},
										{
											id: 'browser',
											label: 'Browser',
											type: 'select',
											selectOptions: [
												{'value': 'chrome', 'displayValue': 'Google Chrome'},
												{'value': 'firefox', 'displayValue': 'Mozilla Firefox'},
											]
										},
										{
											id: 'document_flag',
											label: 'Document Flag',
											type: 'checkbox'
										}
									]
								},
							'data': optionData,
							'setter': setOptionData
							},
							{
								'metadata': {
									id: 'variableMapFormSection',
									title: 'Variable Maps',
									expandable: true,
									_className: 'FormSection',
									fields: [
										{
											id: 'var_maps',
											label: 'Variable Maps',
											type: 'multisearchbox',
											toolbarButtons: toolbarButtons,
											itemSelectFunction: varMapItemSelectFunction
										},
									]
								},
							'data': varMapData,
							'setter': setVarMapData
							},
							{
								'metadata': {
									id: 'runtimeVarsFormSection',
									title: 'Runtime Variables',
									expandable: true,
									_className: 'SearchFormSection',
									fields: runtimeVarFields
								},
							'data': runtimeVarData,
							'setter': setRuntimeVarData
							},
						]
	
	function submit(){
		let endpoint = '/instruction_set_runs';
		if(window.confirm('Are you sure you want to submit this test run?')){
			let run = new Object();
			let newOption, newMap, newRuntimeMap;
			for (let key in runData){
				run[key] = runData[key];
			}
			run['instruction_set_id'] = instructionSetId;
			run['options'] = new Array();
			for (let key in optionData){
				newOption = new Object();
				if (key == 'document_flag'){
					run['document_flag'] = optionData[key];
				}
				newOption['key'] = key;
				newOption['value'] = optionData[key];
				run['options'].push(newOption);
			}
			run['maps'] = new Array();
			for (let i=0;i<varMapData['var_maps'].length;i++){
				newMap = new Object();
				newMap['variable_map_id'] = varMapData['var_maps'][i]['id'];
				newMap['variable_map_seq'] = i;
				run['maps'].push(newMap);
			}
			let runtimeVarsObj = new Object();
			let key, keySplit, mapId, mapItemKey;
			for (let i=0;i < runtimeVarFields.length;i++){
				let field = runtimeVarFields[i];
				key = field['id'];
				keySplit = key.split('~');
				mapId = keySplit.splice(0,1)[0]
				mapItemKey = keySplit.join('~');
				if (!runtimeVarsObj.hasOwnProperty(mapId)){
					runtimeVarsObj[mapId] = new Object();
				}
				runtimeVarsObj[mapId][mapItemKey] = '';
			}
			for (key in runtimeVarData){
				keySplit = key.split('~');
				mapId = keySplit.splice(0,1)[0]
				mapItemKey = keySplit.join('~');
				if (runtimeVarsObj.hasOwnProperty(mapId)){
					if (runtimeVarsObj[mapId].hasOwnProperty(mapItemKey)){
						runtimeVarsObj[mapId][mapItemKey] = runtimeVarData[key];
					}
				}
			}
			run['runtime_vars'] = runtimeVarsObj;
			SessionRequest(endpoint, 'POST', {}, run);
			navigate('/instruction_set_runs/view');
		}
	}
	
	function close(){
		navigate(-1);
	}
	
	let formButtons = [
		{
			'id':'submitButton',
			'label': 'Submit',
			'_className': 'TextButton',
			'fn': submit
		},
		{
			'id':'closeButton',
			'label': 'Close',
			'_className': 'TextButton',
			'fn': close
		}
	]
	
	/**** MODAL ****/
	var modalTableData = {
						'metadata': {
							'id': 'varMapsModalTable',
							'title': 'Variable Maps',
							'columns':[
								{
									'id': 'name',
									'label': 'Name'
								}
							]
						},
						'data': modalData
					}
	
	let modalSearchFormData = [{
			'metadata': {
						id: 'searchFormData',
						title: null,
						expandable: true,
						_className: 'SearchFormSection',
						fields: [
							{
								id: 'name',
								label: 'Name',
								placeholder: 'Name',
							}
						]
					},
			'data': modalSearchData,
			'setter': setModalSearchData
		}]

	async function modalSearchFunction(){
		let endpoint = '/variable_maps?limit=25&order_by=map_items.id&expand=map_items'
		let qParam = window._getCommonFunction('buildSearchQuery')(modalSearchData);
		endpoint += "&q=active_flag='Y'"+qParam;
		let response = await SessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			setModalData(response['json']['items']);
		}
	}
	
	async function modalSelectFunction(data){
		setSelectedModalData(data);
		setModalAddMapDisabled(false);
	}
	
	async function modalDeselectFunction(data){
		setSelectedModalData(null);
		setModalAddMapDisabled(true);
	}
	
	var modalToolbarButtons = [
		{
			'id':'pull_map', 
			'label': 'Add Variable Map',
			'_className': 'ToolbarButton', 
			'icon':faSquarePlus, 
			'fn': addVarMap,
			'disabled': modalAddMapDisabled
		}
	]

	useEffect(() => {
			getClients();
			getVarSets();
		},[]);
	

	return(
		<div className = 'InstructionSetRunCreate'>
			<Banner icon={faVial} bannerText="Run Test"/>
			<WorkArea>
				<ViewEditSingleForm title='Test Run'
					formData = {formData}
					formButtons={formButtons} />
			</WorkArea>
			<ViewManySearchTableModal id='varMapSearchModal'
					searchFormData={modalSearchFormData} 
					searchFunction={modalSearchFunction} 
					toolbarButtons={modalToolbarButtons} 
					tableData={modalTableData}
					selectFunction={modalSelectFunction}
					deselectFunction={modalDeselectFunction}/>
		</div>
	)
	
	
}

export default InstructionSetRunCreate;