import './ClientViewMany.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import { useState, useEffect } from "react";
import {SessionRequest, CachedSessionRequest} from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop, faCircleCheck, faEye, faPlus, faXmark, faPenToSquare, faAngleRight, faAngleLeft, faRotate} from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';

function ClientViewMany(props){
	
	const navigate = useNavigate();
	
	var defaultSearchData = {
								'name': ''
							}
	
	const [searchData, setSearchData] = useState(defaultSearchData);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [addDisabled, setAddDisabled] = useState(false);
	const [tablebackDisabled, setTablebackDisabled] = useState(true);
	const [tableforwardDisabled, setTableforwardDisabled] = useState(true);
	const [viewDisabled, setViewDisabled] = useState(true);
	const [editDisabled, setEditDisabled] = useState(true);
	const [page, setPage] = useState(0);
	
	var searchFormData = [
		{
			'metadata': {
							id: 'searchFormData',
							title: null,
							expandable: true,
							_className: 'SearchFormSection',
							fields: [
								{
									id: 'name',
									label: 'Name',
									placeholder: 'Name',
								}							
							]
						},
			'data': searchData,
			'setter': setSearchData
		}
	];
	
	var tableData = {
						'metadata': {
										'id': 'instructionSetsTable',
										'title': 'Instruction Sets',
										'columns':[
											{
												'id': 'name',
												'label': 'Name'
											},
											{
												'id': 'description',
												'label': 'Description'
											}
										]
									},
						'data': data
	}
	
	async function searchFunction(page=0){
		let offset = page==0 ? 0 : (page*25)-1;
		let endpoint = '/clients?limit=25&offset='+offset
		let qParam = window._getCommonFunction('buildSearchQuery')(searchData);
		if (qParam.length > 0){
			endpoint += '&q='+qParam;
		}
		let response = await SessionRequest(endpoint);
		if (response['status_code'] == 200){
			setData(response['json']['items']);
			if (response['json']['has_more'] == true){
				setTableforwardDisabled(false);
			}
			else{
				setTableforwardDisabled(true);
			}
			if (page>0){
				setTablebackDisabled(false);
			}
			else{
				setTablebackDisabled(true);
			}
		}
	}
	
	function selectFunction(data){
		setSelected(data);
		setViewDisabled(false);
		setEditDisabled(false);
	}
	
	function deselectFunction(data){
		setViewDisabled(true);
		setEditDisabled(true);
	}
	
	async function tableBack(){
		if (page > 0){
			let lastPage = page-1;
			setPage(lastPage);
			searchFunction(lastPage);
		}
	}
	
	async function tableForward(){
		let nextPage = page+1;
		setPage(nextPage);
		searchFunction(nextPage);
	}
	
	function addClient(){
		navigate('/clients/0/create');
	}
	
	function viewClient(){
		navigate('/clients/' + selected['id'] +'/view');
	}
	
	function editClient(){
		navigate('/clients/' + selected['id'] +'/edit');
	}
		
	var toolbarButtons = [
		{
			'id':'add', 
			'label': 'Create Client',
			'_className': 'ToolbarButton', 
			'icon':faPlus, 
			fn: addClient,
			disabled: addDisabled
		},
		{
			'id':'refresh', 
			'label': 'Refresh',
			'_className': 'ToolbarButton', 
			'icon':faRotate,
			fn: searchFunction,
		},
		{
			'id':'tableBack', 
			'label': 'Back',
			'_className': 'ToolbarButton', 
			'icon':faAngleLeft,
			fn: tableBack,
			disabled: tablebackDisabled
		},
		{
			'id':'tableForward', 
			'label': 'Forward',
			'_className': 'ToolbarButton', 
			'icon':faAngleRight,
			fn: tableForward,
			disabled: tableforwardDisabled
		},
		{
			'id':'view', 
			'label': 'View Client',
			'_className': 'ToolbarButton', 
			'icon':faEye, 
			fn: viewClient,
			disabled: viewDisabled
		},
		{
			'id':'edit', 
			'label': 'Edit Client',
			'_className': 'ToolbarButton', 
			'icon':faPenToSquare, 
			fn: editClient,
			disabled: editDisabled
		}
	]
	
	return(
		<div className = 'ClientViewMany'>
			<div>
			<Banner icon={faShop} bannerText="Clients"/>
			<WorkArea>
				<ViewManySearchTable defaultSearchData={defaultSearchData} 
					searchFormData={searchFormData} 
					searchFunction={searchFunction} 
					toolbarButtons={toolbarButtons} 
					tableData={tableData}
					selectFunction={selectFunction}
					deselectFunction={deselectFunction}/>
			</WorkArea>
			</div>
		</div>
	)
	
	
}

export default ClientViewMany;