import './ViewManySearchTable.css'
import Table from '../Components/Table.js';
import Toolbar from '../Components/Toolbar.js';
import { Form } from '../Components/Form.js';

function ViewManySearchTable(props){
	
	async function searchFunction(e){
		await props.searchFunction();
		document.getElementById('searchForm').classList.add('FormCollapsed');
	}
	
	var formButtons = [
		{
			'id':'searchButton', 
			'label':'Search', 
			'_className': 'TextButton DefaultButton', 
			'fn': searchFunction
		}
	]
	
	return(
		<div className = 'ViewManySearchTable'>
			<Form id = 'searchForm' 
				title='Search' 
				expandable={true} 
				sections = {props.searchFormData} 
				buttons= {formButtons} 
				_className = 'SearchForm'/>
			<Toolbar buttons={props.toolbarButtons} />
			
			<Table id='table' TableData={props.tableData} 
				selectFunction={props.selectFunction}/>
		</div>
	)
	
	
}

export default ViewManySearchTable;