import './InstructionSetViewMany.css'
import Banner from '../../Components/Banner.js';
import WorkArea from '../../Components/WorkArea.js';
import ViewManySearchTable from '../../Views/ViewManySearchTable.js';
import { useState, useEffect } from "react";
import { SessionRequest, CachedSessionRequest } from '../../Events/Requester.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskVial, faUser, faEye, faTableList, faPlus,faFileCode, faPlay, faXmark, faPenToSquare, faAngleRight, faAngleLeft, faRotate} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function InstructionSetViewMany(props){
	
	const navigate = useNavigate();
	
	var defaultSearchData = {
							'name': '',
							'description': '',
							'category': '',
							'client': '',
							'@Module': '',
							'@Business Scenario': '',
							'@Test Case Code': '',
							'@Test Case Category': ''
							}
	
	const [searchData, setSearchData] = useState(defaultSearchData);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [addDisabled, setAddDisabled] = useState(false);
	const [tablebackDisabled, setTablebackDisabled] = useState(true);
	const [tableforwardDisabled, setTableforwardDisabled] = useState(true);
	const [viewDisabled, setViewDisabled] = useState(true);
	const [editDisabled, setEditDisabled] = useState(true);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [runDisabled, setRunDisabled] = useState(true);
	const [varsetDisabled, setVarsetDisabled] = useState(true);
	const [prevrunsDisabled, setPrevrunsDisabled] = useState(true);
	const [clientOptions, setClientOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [moduleOptions, setModuleOptions] = useState([]);
	const [page, setPage] = useState(0);
	
	async function getClients(){
		let endpoint = '/clients?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedClientOptions = new Array();
			updatedClientOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let client = new Object();
				client['value'] = j['items'][i]['id'];
				client['displayValue'] = j['items'][i]['name'];
				updatedClientOptions.push(client);
			}
			await setClientOptions(updatedClientOptions);
		}
	}
	
	async function getCategories(){
		let endpoint = '/instruction_set_categories?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedCategoryOptions = new Array();
			updatedCategoryOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let category = new Object();
				category['value'] = j['items'][i]['name'];
				category['displayValue'] = j['items'][i]['name'];
				updatedCategoryOptions.push(category);
			}
			await setCategoryOptions(updatedCategoryOptions);
		}
	}
	
	async function getModules(){
		let endpoint = '/instruction_set_modules?fields=id,name&limit=500';
		let response = await CachedSessionRequest(endpoint);
		if (response['status_code'] == 200){
			let j = await response['json']
			let updatedModuleOptions = new Array();
			updatedModuleOptions.push({'value': '', 'displayValue': ''});
			for (let i=0; i<j['items'].length;i++){
				let module = new Object();
				module['value'] = j['items'][i]['name'];
				module['displayValue'] = j['items'][i]['name'];
				updatedModuleOptions.push(module);
			}
			await setModuleOptions(updatedModuleOptions);
		}
	}
	
	var searchFormData = [
		{
			'metadata': {
						id: 'searchFormData',
						title: null,
						expandable: true,
						_className: 'SearchFormSection',
						fields: [
							{
								id: 'name',
								label: 'Name',
								placeholder: 'Name',
							},
							{
								id: 'description',
								label: 'Description',
								placeholder: 'Description'
							},
							{
								id: 'category',
								label: 'Category',
								placeholder: 'Category',
								type: 'select',
								selectOptions: categoryOptions
							},
							{
								id: 'client_id',
								label: 'Client',
								placeholder: 'Client',
								type: 'select',
								selectOptions: clientOptions
							},
							{
								id: '@Module',
								label: 'Module',
								placeholder: 'Module',
								type: 'select',
								selectOptions: moduleOptions
							},
							{
								id: '@Business Scenario',
								label: 'Business Scenario',
								placeholder: 'Business Scenario'
							},
							{
								id: '@Test Case Code',
								label: 'Test Case Code',
								placeholder: 'Test Case Code'
							},
							{
								id: '@Test Case Category',
								label: 'Test Case Category',
								placeholder: 'Test Case Category'
							}							
						]
					},
			'data': searchData,
			'setter': setSearchData
		}
	];
	
	var tableData = {
						'metadata': {
										'id': 'instructionSetsTable',
										'title': 'Instruction Sets',
										'columns':[
											{
												'id': 'name',
												'label': 'Name'
											},
											{
												'id': 'description',
												'label': 'Description'
											},
											{
												'id': 'category',
												'label': 'Category'
											},
											{
												'id': 'client',
												'label': 'Client'
											},
											{
												'id': '@Module',
												'label': 'Module'
											},
											{
												'id': '@Business Scenario',
												'label': 'Business Scenario'
											},
											{
												'id': '@Test Case Code',
												'label': 'Test Case Code'
											},
											{
												'id': '@Test Case Category',
												'label': 'Test Case Category'
											}
										]
									},
						'data': data
	}
	
	async function searchFunction(page=0){
		let offset = page==0 ? 0 : (page*25)-1;
		let endpoint = "/instruction_sets?limit=25&offset="+offset+"&expand=attributes&q=active_flag='Y'"
		let qParam = window._getCommonFunction('buildSearchQuery')(searchData);
		if (qParam.length > 0){
			endpoint += " and " + qParam;
		}
		
		let response = await SessionRequest(endpoint);
		
		if (response['status_code'] == 200){
			let items = response['json']['items'];
			for (let i=0; i<items.length;i++){
				let itemAttributes = items[i]['attributes'];
				if (itemAttributes != null){
					for (let j=0;j<itemAttributes.length;j++){
						items[i]['@'+itemAttributes[j]['attr_name']] = itemAttributes[j]['value'];
					}
				}
			}
			setData(items);
			if (response['json']['has_more'] == true){
				setTableforwardDisabled(false);
			}
			else{
				setTableforwardDisabled(true);
			}
			if (page>0){
				setTablebackDisabled(false);
			}
			else{
				setTablebackDisabled(true);
			}
		}
	}
	
	function selectFunction(data){
		setSelected(data);
		setViewDisabled(false);
		setEditDisabled(false);
		setDeleteDisabled(false);
		setRunDisabled(false);
		setVarsetDisabled(false);
		setPrevrunsDisabled(false);
	}
	
	function deselectFunction(data){
		setViewDisabled(true);
		setEditDisabled(true);
		setDeleteDisabled(true);
		setRunDisabled(true);
		setVarsetDisabled(true);
		setPrevrunsDisabled(true);
	}
	
	async function tableBack(){
		if (page > 0){
			let lastPage = page-1;
			setPage(lastPage);
			searchFunction(lastPage);
		}
	}
	
	async function tableForward(){
		let nextPage = page+1;
		setPage(nextPage);
		searchFunction(nextPage);
	}
	
	function addInstructionSet(){
		navigate('/instruction_sets/0/create');
	}
	
	function viewInstructionSet(){
		navigate('/instruction_sets/' + selected['id'] + '/view');
	}
	
	function editInstructionSet(){
		navigate('/instruction_sets/' + selected['id'] + '/edit');
	}
	
	async function deleteInstructionSet(){
		if(window.confirm("Are you sure you want to delete?")){
			let endpoint = '/instruction_sets/' + selected['id'];
			let response = await SessionRequest(endpoint, 'DELETE');
			if (response['status_code'] == 204){
				alert('Successfully deleted instruction set.');
			}
			else{
				alert('An error occured while deleting instruction set.');
			}
		}
	}
	
	function runInstructionSet(){
		navigate('/instruction_sets/'+ selected['id'] + '/runs/create');
	}
	
	function viewVariableSets(){
		navigate('/instruction_sets/'+ selected['id'] + '/variable_sets/view');
	}
	
	function viewPrevRuns(){
		navigate('/instruction_set_runs/0/'+ selected['id'] + '/view');
	}
	
	var toolbarButtons = [
		{
			'id':'add', 
			'label': 'Create Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faPlus, 
			fn: addInstructionSet,
			disabled: addDisabled
		},
		{
			'id':'refresh', 
			'label': 'Refresh',
			'_className': 'ToolbarButton', 
			'icon':faRotate,
			fn: searchFunction,
		},
		{
			'id':'tableBack', 
			'label': 'Back',
			'_className': 'ToolbarButton', 
			'icon':faAngleLeft,
			fn: tableBack,
			disabled: tablebackDisabled
		},
		{
			'id':'tableForward', 
			'label': 'Forward',
			'_className': 'ToolbarButton', 
			'icon':faAngleRight,
			fn: tableForward,
			disabled: tableforwardDisabled
		},
		{
			'id':'view', 
			'label': 'View Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faEye, 
			fn: viewInstructionSet,
			disabled: viewDisabled
		},
		{
			'id':'edit', 
			'label': 'Edit Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faPenToSquare, 
			fn: editInstructionSet,
			disabled: editDisabled
		},
		{
			'id':'delete', 
			'label': 'Delete Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faXmark, 
			fn: deleteInstructionSet,
			disabled: deleteDisabled
		},
		{
			'id':'run', 
			'label': 'Run Instruction Set',
			'_className': 'ToolbarButton', 
			'icon':faPlay, 
			fn: runInstructionSet,
			disabled: runDisabled
		},
		{
			'id':'varset', 
			'label': 'Variable Sets',
			'_className': 'ToolbarButton', 
			'icon':faFileCode, 
			fn: viewVariableSets,
			disabled: varsetDisabled
		},
		{
			'id':'prevruns',
			'label': 'Previous Runs',
			'_className': 'ToolbarButton', 
			'icon':faTableList, 
			fn: viewPrevRuns,
			disabled: prevrunsDisabled
		}		
	]
	
	useEffect(() => {
			getClients();
			getModules();
			getCategories();
		},[]);
	
	return(
		<div className = 'InstructionSetViewMany'>
			<div>
			<Banner icon={faFlaskVial} bannerText="Instruction Sets"/>
			<WorkArea>
				<ViewManySearchTable defaultSearchData={defaultSearchData} 
					searchFormData={searchFormData} 
					searchFunction={searchFunction} 
					toolbarButtons={toolbarButtons} 
					tableData={tableData}
					selectFunction={selectFunction}
					deselectFunction={deselectFunction}/>
			</WorkArea>
			</div>
		</div>
	)
	
	
}

export default InstructionSetViewMany;