import './Table.css'

function Table(props){
	
	let metadata = props.TableData['metadata'];
	let selectFunction = props.selectFunction;
	
	return (
		<div className='TableContainer'>
		<table className="Table" id={metadata['id']}>
			<thead>
				<TableHeader Columns={metadata['columns']} />
			</thead>
			<TableBody Data={props.TableData['data']} Columns={metadata['columns']} selectFunction={selectFunction} />
		</table>
		</div>
	);
}

function TableHeader(props){
	
	let headersInitialized = false;
	
	function initializeHeaderWidths(){
		let elements = document.getElementsByClassName("TableHeader");
		for (let i=0;i < elements.length; i++){
			let tableHeader = elements[i];
			let headerCells = tableHeader.getElementsByClassName("TableHeaderCell");
			for (let j=0;j<headerCells.length;j++){
				headerCells[j].style['width'] = headerCells[j].getBoundingClientRect().width + 'px';
			}
		}
		headersInitialized = true;
	}
	
	function startDrag(e){
		e.preventDefault();
		if (!headersInitialized){
			initializeHeaderWidths();
		}
		let borderElement, leftHeader, rightHeader, widthLeft, widthRight;
		if (e.target.classList.contains("TableHeaderBorder")){
			borderElement = e.target;
		}
		else{
			borderElement = e.target.parentElement;
		}
		leftHeader = borderElement.parentElement;
		rightHeader = borderElement.parentElement.nextSibling;
		widthLeft = leftHeader.getBoundingClientRect().width;
		if (rightHeader !== null){
			let widthRight = rightHeader.getBoundingClientRect().width;
		}
		let startX = e.clientX;
		
		function resizeColumn(e){
			e.preventDefault();
			document.body.style.cursor = "ew-resize";
			let newClientX = e.clientX;
			let dx = newClientX-startX;
			let newWidthLeft = widthLeft+dx;
			let newWidthRight = widthRight-dx;
			leftHeader.style['width'] = newWidthLeft + 'px';
			if (rightHeader !== null){
				rightHeader.style['width'] = newWidthRight + 'px';
			}
		}
		
		function stopResize(e){
			document.body.style.cursor = "auto";
			document.onmousemove = null;
			document.onmouseup = null;
		}
		
		document.onmousemove = resizeColumn;
		document.onmouseup = stopResize;
	}
	
	return (
			<tr className="TableHeader">
				{props.Columns.map((column) => <TableHeaderCell key={column['id']} label={column['label']}>{column['label']}<span className="TableHeaderBorder" onMouseDown={startDrag}><span className="TableHeaderBorderLine"/></span></TableHeaderCell>)} 
			</tr>
	);
}

function TableHeaderCell(props){

	return (
	
		<th className="TableHeaderCell" title={typeof(props.children)==='string'?props.children:null}>
			{props.children}
		</th>
		
	);
}

function TableBody(props){
	return (
		<tbody className="TableBody">
			{props.Data.map((row, i) => <TableRow key={'row_' + i} id={i} Columns={props.Columns} Row={row} Index={i} selectFunction={props.selectFunction}/>) }
		</tbody>
	);
	
}

function TableRow(props){
	
	function findParentByClass(element, _className){
		let parentFound = false;
		while (true){
			if (element.classList.contains(_className)){
				parentFound = false;
				return element;
			}
			if (element.parentElement == null){
				return null;
			}
			element = element.parentElement;
		}
	}
	
	function clearSelected(element){
		let parentTreeview = findParentByClass(element, 'Table')
		let branchHeaders = parentTreeview.getElementsByClassName('TableRow');
		for (let i=0; i<branchHeaders.length; i++){
			if (branchHeaders[i].classList.contains('TableRowSelected')){
				branchHeaders[i].classList.remove('TableRowSelected');
			}
		}
	}
	
	function selectRow(e){
		let element = e.target;
		clearSelected(element);
		element = findParentByClass(element, 'TableRow')
		element.classList.toggle('TableRowSelected');
		props.selectFunction(props.Row);
	}
	
	return (
		<tr className='TableRow' onClick={selectRow}>
			{props.Columns.map((col, i) => 
				<TableCell key={i} Value={props.Row[col['id']]}  Index={props.Index} id={props.id} >{props.Row[col['id']]}</TableCell>)}
		</tr>
	);
}

function TableCell(props){

	return (
		<td className="TableCell" title={typeof(props.children)==='string'?props.children:null}>
			{props.children}
		</td>
	);
}


export default Table;