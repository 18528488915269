import './Button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconButton(props){
	
	const id = props.id;
	
	async function _onClick(e){
		if (typeof(props.fn) == 'function'){
			document.getElementById(id).disabled = true;
			await props.fn(e);
			try{
				document.getElementById(id).disabled = false;
			}catch (err) {}
		}
	}
	
	return (
		<button id={id} className={props._className} onClick={_onClick} title={props.label} disabled={props.disabled}>
			<FontAwesomeIcon style={{height: '24px', width: '24px'}} icon={props.icon} size='xl' inverse/>
		</button>
	);
}


function TextButton(props){
	
	const id = props.id;
	
	async function _onClick(e){
		if (typeof(props.fn) == 'function'){
			document.getElementById(id).disabled = true;
			try{
				await props.fn();
			} catch (err) {};
			try{
				document.getElementById(id).disabled = false;
			}catch (err) {}
		}
	}
	
	return (
		<button id={id} className={props._className} onClick={_onClick}>
			{props.label}
		</button>
	);
}

export {IconButton, TextButton};