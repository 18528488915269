import './Container.css';

function Container(props){
	
	const containerType = (props.containerType || '') + 'Container';
	
	return (
		<div className={containerType} style={props.containerStyle}>
			{props.children}
		</div>
	);
}

export default Container;